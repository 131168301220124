main {
    //padding-top: 85px;
}
.container-xs {
    max-width: 576px!important;
}

.container-sm {
    max-width: 768px!important;
}

.container-md {
    max-width: 992px!important;
}

@media (max-width: 991.98px) {
    .offcanvas-collapse {
      position: fixed;
      top: 56px; /* Height of navbar */
      bottom: 0;
      left: 100%;
      width: 100%;
      padding-right: 1rem;
      padding-left: 1rem;
      overflow-y: auto;
      visibility: hidden;
      background-color: #343a40;
      transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }
    .offcanvas-collapse.open {
      visibility: visible;
      transform: translateX(-100%);
    }
}
.btn {
    color: white!important;
    font-weight: 500!important;
}
.btn-primary {
    color: #000 !important;
}
.btn-outline-primary {
    color: $primary !important;
    &:hover {
        color: #000 !important;
    }
}
.btn-white {
    color: #000!important;
}
.btn-black {
    &:hover{
        background-color: #222!important;
    }
}
// brand
.navbar-brand {
    img {
      width: auto;
      max-height: 65px!important;
    }
}
.navbar {
    li.active > a {
        color: $primary;
    }
}

.py-300 {
    padding-top: 300px;
    padding-bottom: 300px;
}

// figure
.background-top, .bg-top {
    background-position: top center!important;
    figure {
        background-position: top center!important;
    }
}
.background-bottom, .bg-bottom {
    background-position: bottom center!important;
    figure {
        background-position: bottom center!important;
    }
}
.nav-item {
    font-size: 1rem;
    margin: 0 20px;
    padding: 20px 0!important;
    &.active {
        border-bottom: 3px solid #fff;
        padding-bottom: 17px!important;
    }
    a.nav-link {
        padding: 0!important;
        font-weight: 500;
    }
}
.lh-11, .lh-s {
    line-height: 1.1!important;
}
.lh-15, .lh-md {
    line-height: 1.5!important;
}
.lh-2, .lh-lg {
    line-height: 2!important;
}
.lh-25, .lh-xl {
    line-height: 2.5!important;
}
.lh-3, .lh-xxl {
    line-height: 3!important;
}

.fs-20 {
    font-size: 20px!important;
}
.smaller {
    font-size: .775em!important;
}
.smallest {
    font-size: .675em!important;
}
.lift {
    transition: box-shadow .1s ease,transform .25s ease !important;
    &:hover, &:focus {
        box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
        transform: translate3d(0,-3px,0);
    }
    &.lift-lg
    {
        &:hover, &:focus {
            box-shadow: 0 2rem 5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.05)!important;
            transform: translate3d(0,-5px,0) !important;
        }
    }
}
.raise {
    transition: transform .2s ease !important;
    &:hover, &:focus {
        transform: translate3d(0,-2px,0);
    }
}
.dim {
    transition: 0.2s !important;
    opacity: 1;
    &:hover, &:focus {
        opacity: .85;
    }
}
.mw-25 {
    max-width: 25%!important;
}
.mw-50 {
    max-width: 50%!important;
}
.mw-75 {
    max-width: 75%!important;
}
.border-black {
    border-color: $black !important;
}
.context {
    padding: 8px 18px;
    text-align: center;
    margin-bottom: 15px;
    display: inline-block;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1 !important;
    font-weight: 700;
    background-color: $black;
    color: $context;
    &.inverted {
        background-color: $context !important;
        color: $black !important;
    }
    &.context-o{
        background-color: rgba(255, 255, 255, .1) !important;
        color: #fff !important;
        text-transform: none;
        letter-spacing: normal;
        font-size: 12px;
    }
}
.block-titles-title {
    margin-bottom: 1rem !important;
}

.box-shadow {
    box-shadow: 0 0 15px #222;
}
.bgo {
    background-color: rgba(0, 0, 0, .75);
}

.level-4 {
    z-index: 4;
}
.level-5 {
    z-index: 5;
}
.level-6 {
    z-index: 6;
}
.level-7 {
    z-index: 7;
}
.level-8 {
    z-index: 8;
}
.level-9 {
    z-index: 9;
}
.ava {
    object-fit: cover;
    object-position: top center;
    border-radius:50%;
    width: 100%;
    height: 100%;
}
.rounded-5 {
    border-radius: 5px !important;
}

.rounded-6 {
    border-radius: 6px !important;
}
.rounded-10 {
    border-radius: 10px !important;
}

.bg-darker {
    background-color: #111;
}
.bg-purple {
    background: #101336;
}
