@charset "UTF-8";
.container-xs {
  max-width: 576px !important;
}

.container-sm {
  max-width: 768px !important;
}

.container-md {
  max-width: 992px !important;
}

@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px;
    /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}
.btn {
  color: white !important;
  font-weight: 500 !important;
}

.btn-primary {
  color: #000 !important;
}

.btn-outline-primary {
  color: rgb(248, 187, 0) !important;
}
.btn-outline-primary:hover {
  color: #000 !important;
}

.btn-white {
  color: #000 !important;
}

.btn-black:hover {
  background-color: #222 !important;
}

.navbar-brand img {
  width: auto;
  max-height: 65px !important;
}

.navbar li.active > a {
  color: rgb(248, 187, 0);
}

.py-300 {
  padding-top: 300px;
  padding-bottom: 300px;
}

.background-top, .bg-top {
  background-position: top center !important;
}
.background-top figure, .bg-top figure {
  background-position: top center !important;
}

.background-bottom, .bg-bottom {
  background-position: bottom center !important;
}
.background-bottom figure, .bg-bottom figure {
  background-position: bottom center !important;
}

.nav-item {
  font-size: 1rem;
  margin: 0 20px;
  padding: 20px 0 !important;
}
.nav-item.active {
  border-bottom: 3px solid #fff;
  padding-bottom: 17px !important;
}
.nav-item a.nav-link {
  padding: 0 !important;
  font-weight: 500;
}

.lh-11, .lh-s {
  line-height: 1.1 !important;
}

.lh-15, .lh-md {
  line-height: 1.5 !important;
}

.lh-2, .lh-lg {
  line-height: 2 !important;
}

.lh-25, .lh-xl {
  line-height: 2.5 !important;
}

.lh-3, .lh-xxl {
  line-height: 3 !important;
}

.fs-20 {
  font-size: 20px !important;
}

.smaller {
  font-size: 0.775em !important;
}

.smallest {
  font-size: 0.675em !important;
}

.lift {
  transition: box-shadow 0.1s ease, transform 0.25s ease !important;
}
.lift:hover, .lift:focus {
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
  transform: translate3d(0, -3px, 0);
}
.lift.lift-lg:hover, .lift.lift-lg:focus {
  box-shadow: 0 2rem 5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.05) !important;
  transform: translate3d(0, -5px, 0) !important;
}

.raise {
  transition: transform 0.2s ease !important;
}
.raise:hover, .raise:focus {
  transform: translate3d(0, -2px, 0);
}

.dim {
  transition: 0.2s !important;
  opacity: 1;
}
.dim:hover, .dim:focus {
  opacity: 0.85;
}

.mw-25 {
  max-width: 25% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.border-black {
  border-color: #000000 !important;
}

.context {
  padding: 8px 18px;
  text-align: center;
  margin-bottom: 15px;
  display: inline-block;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1 !important;
  font-weight: 700;
  background-color: #000000;
  color: #ffffff;
}
.context.inverted {
  background-color: #ffffff !important;
  color: #000000 !important;
}
.context.context-o {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 12px;
}

.block-titles-title {
  margin-bottom: 1rem !important;
}

.box-shadow {
  box-shadow: 0 0 15px #222;
}

.bgo {
  background-color: rgba(0, 0, 0, 0.75);
}

.level-4 {
  z-index: 4;
}

.level-5 {
  z-index: 5;
}

.level-6 {
  z-index: 6;
}

.level-7 {
  z-index: 7;
}

.level-8 {
  z-index: 8;
}

.level-9 {
  z-index: 9;
}

.ava {
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.rounded-5 {
  border-radius: 5px !important;
}

.rounded-6 {
  border-radius: 6px !important;
}

.rounded-10 {
  border-radius: 10px !important;
}

.bg-darker {
  background-color: #111;
}

.bg-purple {
  background: #101336;
}

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #4726e1;
  --bs-indigo: #6610f2;
  --bs-purple: #300d81;
  --bs-pink: #d63384;
  --bs-red: #d34f2d;
  --bs-orange: #f08d34;
  --bs-yellow: rgb(248, 187, 0);
  --bs-green: #1d4b40;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #ffffff;
  --bs-gray: #999999;
  --bs-gray-dark: #555555;
  --bs-black: #000000;
  --bs-gold: #807764;
  --bs-border-color: #dddddd;
  --bs-gray-100: #eeeeee;
  --bs-gray-200: #dddddd;
  --bs-gray-300: #bbbbbb;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #999999;
  --bs-gray-700: #777777;
  --bs-gray-800: #555555;
  --bs-gray-900: #222222;
  --bs-primary: rgb(248, 187, 0);
  --bs-secondary: #555555;
  --bs-success: #1d4b40;
  --bs-info: #0dcaf0;
  --bs-warning: rgb(248, 187, 0);
  --bs-danger: #d34f2d;
  --bs-light: #eeeeee;
  --bs-dark: #222222;
  --bs-context: #ffffff;
  --bs-primary-rgb: 248, 187, 0;
  --bs-secondary-rgb: 85, 85, 85;
  --bs-success-rgb: 29, 75, 64;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 248, 187, 0;
  --bs-danger-rgb: 211, 79, 45;
  --bs-light-rgb: 238, 238, 238;
  --bs-dark-rgb: 34, 34, 34;
  --bs-context-rgb: 255, 255, 255;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Open+sans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1.2rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.7;
  --bs-body-color: #000000;
  --bs-body-bg: #ffffff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-family: "Ubuntu", "serif";
  font-weight: 500;
  line-height: 1.25;
  color: rgb(248, 187, 0);
}

h1, .h1 {
  font-size: calc(1.485rem + 2.82vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 3.6rem;
  }
}

h2, .h2 {
  font-size: calc(1.425rem + 2.1vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 3rem;
  }
}

h3, .h3 {
  font-size: calc(1.389rem + 1.668vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 2.64rem;
  }
}

h4, .h4 {
  font-size: calc(1.365rem + 1.38vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 2.4rem;
  }
}

h5, .h5 {
  font-size: calc(1.305rem + 0.66vw);
}
@media (min-width: 1200px) {
  h5, .h5 {
    font-size: 1.8rem;
  }
}

h6, .h6 {
  font-size: 1.2rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgb(248, 187, 0);
  text-decoration: none;
}
a:hover {
  color: #c69600;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #222222;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #777777;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 400;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.5rem;
  }
}

.display-1 {
  font-size: calc(1.557rem + 3.684vw);
  font-weight: 500;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 4.32rem;
  }
}

.display-2 {
  font-size: calc(1.485rem + 2.82vw);
  font-weight: 500;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 3.6rem;
  }
}

.display-3 {
  font-size: calc(1.4418rem + 2.3016vw);
  font-weight: 500;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 3.168rem;
  }
}

.display-4 {
  font-size: calc(1.413rem + 1.956vw);
  font-weight: 500;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 2.88rem;
  }
}

.display-5 {
  font-size: calc(1.341rem + 1.092vw);
  font-weight: 500;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 2.16rem;
  }
}

.display-6 {
  font-size: calc(1.269rem + 0.228vw);
  font-weight: 500;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 1.44rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.5rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #999999;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #bbbbbb;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #999999;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 20px);
  padding-left: var(--bs-gutter-x, 20px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 40px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.5rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.5rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 1rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 1rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1.5rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1.5rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 2rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 2rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2.5rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2.5rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 3rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 3rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3.5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3.5rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 4rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 4rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4.5rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4.5rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 5rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 7.5rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 7.5rem;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 10rem;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 10rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 4.5rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 5rem;
  }

  .g-sm-15,
.gx-sm-15 {
    --bs-gutter-x: 7.5rem;
  }

  .g-sm-15,
.gy-sm-15 {
    --bs-gutter-y: 7.5rem;
  }

  .g-sm-20,
.gx-sm-20 {
    --bs-gutter-x: 10rem;
  }

  .g-sm-20,
.gy-sm-20 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 1rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 1rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 2rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 2rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 3rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 3rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 4rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 4rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 4.5rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 5rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 5rem;
  }

  .g-md-15,
.gx-md-15 {
    --bs-gutter-x: 7.5rem;
  }

  .g-md-15,
.gy-md-15 {
    --bs-gutter-y: 7.5rem;
  }

  .g-md-20,
.gx-md-20 {
    --bs-gutter-x: 10rem;
  }

  .g-md-20,
.gy-md-20 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 4.5rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 5rem;
  }

  .g-lg-15,
.gx-lg-15 {
    --bs-gutter-x: 7.5rem;
  }

  .g-lg-15,
.gy-lg-15 {
    --bs-gutter-y: 7.5rem;
  }

  .g-lg-20,
.gx-lg-20 {
    --bs-gutter-x: 10rem;
  }

  .g-lg-20,
.gy-lg-20 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 5rem;
  }

  .g-xl-15,
.gx-xl-15 {
    --bs-gutter-x: 7.5rem;
  }

  .g-xl-15,
.gy-xl-15 {
    --bs-gutter-y: 7.5rem;
  }

  .g-xl-20,
.gx-xl-20 {
    --bs-gutter-x: 10rem;
  }

  .g-xl-20,
.gy-xl-20 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 5rem;
  }

  .g-xxl-15,
.gx-xxl-15 {
    --bs-gutter-x: 7.5rem;
  }

  .g-xxl-15,
.gy-xxl-15 {
    --bs-gutter-y: 7.5rem;
  }

  .g-xxl-20,
.gx-xxl-20 {
    --bs-gutter-x: 10rem;
  }

  .g-xxl-20,
.gy-xxl-20 {
    --bs-gutter-y: 10rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #000000;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #000000;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #000000;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #000000;
  vertical-align: top;
  border-color: #dddddd;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #fef1cc;
  --bs-table-striped-bg: #f1e5c2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e5d9b8;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ebdfbd;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #e5d9b8;
}

.table-secondary {
  --bs-table-bg: #dddddd;
  --bs-table-striped-bg: #d2d2d2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c7c7c7;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #cccccc;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #c7c7c7;
}

.table-success {
  --bs-table-bg: #d2dbd9;
  --bs-table-striped-bg: #c8d0ce;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bdc5c3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c2cbc9;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #bdc5c3;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fef1cc;
  --bs-table-striped-bg: #f1e5c2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e5d9b8;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ebdfbd;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #e5d9b8;
}

.table-danger {
  --bs-table-bg: #f6dcd5;
  --bs-table-striped-bg: #ead1ca;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #ddc6c0;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e4ccc5;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #ddc6c0;
}

.table-light {
  --bs-table-bg: #eeeeee;
  --bs-table-striped-bg: #e2e2e2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #d6d6d6;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: gainsboro;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #d6d6d6;
}

.table-dark {
  --bs-table-bg: #222222;
  --bs-table-striped-bg: #2d2d2d;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #383838;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #333333;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #383838;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  color: #555555;
}

.col-form-label {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.7;
  color: #555555;
}

.col-form-label-lg {
  padding-top: calc(1.25rem + 1px);
  padding-bottom: calc(1.25rem + 1px);
  font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.35rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  font-size: 1.05rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #777777;
}

.form-control {
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7;
  color: #000000;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #000000;
  background-color: #ffffff;
  border-color: #fcdd80;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(248, 187, 0, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.7em;
}
.form-control::placeholder {
  color: #999999;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #dddddd;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 1rem 0.75rem;
  margin: -1rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #000000;
  background-color: #dddddd;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #d2d2d2;
}
.form-control::-webkit-file-upload-button {
  padding: 1rem 0.75rem;
  margin: -1rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #000000;
  background-color: #dddddd;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #d2d2d2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  line-height: 1.7;
  color: #000000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.7em + 1.5rem + 2px);
  padding: 0.75rem 0.5rem;
  font-size: 1.05rem;
}
.form-control-sm::file-selector-button {
  padding: 0.75rem 0.5rem;
  margin: -0.75rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.75rem 0.5rem;
  margin: -0.75rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.7em + 2.5rem + 2px);
  padding: 1.25rem 1rem;
  font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.35rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 1.25rem 1rem;
  margin: -1.25rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 1.25rem 1rem;
  margin: -1.25rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.7em + 2rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.7em + 1.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.7em + 2.5rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 1rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.7em;
}
.form-control-color::-webkit-color-swatch {
  height: 1.7em;
}

.form-select {
  display: block;
  width: 100%;
  padding: 1rem 2.25rem 1rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7;
  color: #000000;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23555555' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #fcdd80;
  outline: 0;
  box-shadow: none;
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #dddddd;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}

.form-select-sm {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.5rem;
  font-size: 1.05rem;
}

.form-select-lg {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1rem;
  font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.35rem;
  }
}

.form-check {
  display: block;
  min-height: 2.04rem;
  padding-left: 2em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -2em;
}

.form-check-input {
  width: 1.5em;
  height: 1.5em;
  margin-top: 0.1em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
  transition: 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #fcdd80;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(248, 187, 0, 0.25);
}
.form-check-input:checked {
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='miter' stroke-linejoin='miter' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 10 10'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 3.5em;
}
.form-switch .form-check-input {
  width: 3em;
  margin-left: -3.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fcdd80'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(248, 187, 0, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(248, 187, 0, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: rgb(248, 187, 0);
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fdebb3;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #bbbbbb;
  border-color: transparent;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: rgb(248, 187, 0);
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fdebb3;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #bbbbbb;
  border-color: transparent;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1rem 0.75rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7;
  color: #000000;
  text-align: center;
  white-space: nowrap;
  background-color: #dddddd;
  border: 1px solid #ced4da;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1.25rem 1rem;
  font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
    font-size: 1.35rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.75rem 0.5rem;
  font-size: 1.05rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #1d4b40;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.05rem;
  color: #ffffff;
  background-color: rgba(29, 75, 64, 0.9);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #1d4b40;
  padding-right: calc(1.7em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231d4b40' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.5rem) center;
  background-size: calc(0.85em + 1rem) calc(0.85em + 1rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #1d4b40;
  box-shadow: 0 0 0 0.25rem rgba(29, 75, 64, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.7em + 2rem);
  background-position: top calc(0.425em + 0.5rem) right calc(0.425em + 0.5rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #1d4b40;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23555555' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231d4b40' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.85em + 1rem) calc(0.85em + 1rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #1d4b40;
  box-shadow: 0 0 0 0.25rem rgba(29, 75, 64, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #1d4b40;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #1d4b40;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(29, 75, 64, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #1d4b40;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #d34f2d;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.05rem;
  color: #000000;
  background-color: rgba(211, 79, 45, 0.9);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d34f2d;
  padding-right: calc(1.7em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23d34f2d'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d34f2d' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.5rem) center;
  background-size: calc(0.85em + 1rem) calc(0.85em + 1rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #d34f2d;
  box-shadow: 0 0 0 0.25rem rgba(211, 79, 45, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.7em + 2rem);
  background-position: top calc(0.425em + 0.5rem) right calc(0.425em + 0.5rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #d34f2d;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23555555' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23d34f2d'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d34f2d' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.85em + 1rem) calc(0.85em + 1rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #d34f2d;
  box-shadow: 0 0 0 0.25rem rgba(211, 79, 45, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #d34f2d;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #d34f2d;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 79, 45, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d34f2d;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.7;
  color: #000000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #000000;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(248, 187, 0, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-primary:hover {
  color: #000000;
  background-color: #f9c526;
  border-color: #f9c21a;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #000000;
  background-color: #f9c526;
  border-color: #f9c21a;
  box-shadow: 0 0 0 0 rgba(211, 159, 0, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #000000;
  background-color: #f9c933;
  border-color: #f9c21a;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 159, 0, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}

.btn-secondary {
  color: #ffffff;
  background-color: #555555;
  border-color: #555555;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #484848;
  border-color: #444444;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #ffffff;
  background-color: #484848;
  border-color: #444444;
  box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #444444;
  border-color: #404040;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #ffffff;
  background-color: #555555;
  border-color: #555555;
}

.btn-success {
  color: #ffffff;
  background-color: #1d4b40;
  border-color: #1d4b40;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #194036;
  border-color: #173c33;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #ffffff;
  background-color: #194036;
  border-color: #173c33;
  box-shadow: 0 0 0 0 rgba(63, 102, 93, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #173c33;
  border-color: #163830;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(63, 102, 93, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #ffffff;
  background-color: #1d4b40;
  border-color: #1d4b40;
}

.btn-info {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #000000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0 rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-warning:hover {
  color: #000000;
  background-color: #f9c526;
  border-color: #f9c21a;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000000;
  background-color: #f9c526;
  border-color: #f9c21a;
  box-shadow: 0 0 0 0 rgba(211, 159, 0, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000000;
  background-color: #f9c933;
  border-color: #f9c21a;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 159, 0, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}

.btn-danger {
  color: #000000;
  background-color: #d34f2d;
  border-color: #d34f2d;
}
.btn-danger:hover {
  color: #000000;
  background-color: #da694d;
  border-color: #d76142;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #000000;
  background-color: #da694d;
  border-color: #d76142;
  box-shadow: 0 0 0 0 rgba(179, 67, 38, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #000000;
  background-color: #dc7257;
  border-color: #d76142;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(179, 67, 38, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #000000;
  background-color: #d34f2d;
  border-color: #d34f2d;
}

.btn-light {
  color: #000000;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.btn-light:hover {
  color: #000000;
  background-color: #f1f1f1;
  border-color: #f0f0f0;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000000;
  background-color: #f1f1f1;
  border-color: #f0f0f0;
  box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000000;
  background-color: #f1f1f1;
  border-color: #f0f0f0;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000000;
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.btn-dark {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #1d1d1d;
  border-color: #1b1b1b;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #ffffff;
  background-color: #1d1d1d;
  border-color: #1b1b1b;
  box-shadow: 0 0 0 0 rgba(67, 67, 67, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1b1b1b;
  border-color: #1a1a1a;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(67, 67, 67, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}

.btn-context {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-context:hover {
  color: #000000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-context, .btn-context:focus {
  color: #000000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0 rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-context, .btn-check:active + .btn-context, .btn-context:active, .btn-context.active, .show > .btn-context.dropdown-toggle {
  color: #000000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-context:focus, .btn-check:active + .btn-context:focus, .btn-context:active:focus, .btn-context.active:focus, .show > .btn-context.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(217, 217, 217, 0.5);
}
.btn-context:disabled, .btn-context.disabled {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-primary {
  color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-outline-primary:hover {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(248, 187, 0, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(248, 187, 0, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: rgb(248, 187, 0);
  background-color: transparent;
}

.btn-outline-secondary {
  color: #555555;
  border-color: #555555;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #555555;
  border-color: #555555;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #555555;
  border-color: #555555;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #555555;
  background-color: transparent;
}

.btn-outline-success {
  color: #1d4b40;
  border-color: #1d4b40;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #1d4b40;
  border-color: #1d4b40;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(29, 75, 64, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #ffffff;
  background-color: #1d4b40;
  border-color: #1d4b40;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(29, 75, 64, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #1d4b40;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-outline-warning:hover {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(248, 187, 0, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(248, 187, 0, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: rgb(248, 187, 0);
  background-color: transparent;
}

.btn-outline-danger {
  color: #d34f2d;
  border-color: #d34f2d;
}
.btn-outline-danger:hover {
  color: #000000;
  background-color: #d34f2d;
  border-color: #d34f2d;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(211, 79, 45, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #000000;
  background-color: #d34f2d;
  border-color: #d34f2d;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(211, 79, 45, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #d34f2d;
  background-color: transparent;
}

.btn-outline-light {
  color: #eeeeee;
  border-color: #eeeeee;
}
.btn-outline-light:hover {
  color: #000000;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(238, 238, 238, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000000;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(238, 238, 238, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #eeeeee;
  background-color: transparent;
}

.btn-outline-dark {
  color: #222222;
  border-color: #222222;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(34, 34, 34, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(34, 34, 34, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #222222;
  background-color: transparent;
}

.btn-outline-context {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-context:hover {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:focus + .btn-outline-context, .btn-outline-context:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-context, .btn-check:active + .btn-outline-context, .btn-outline-context:active, .btn-outline-context.active, .btn-outline-context.dropdown-toggle.show {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:checked + .btn-outline-context:focus, .btn-check:active + .btn-outline-context:focus, .btn-outline-context:active:focus, .btn-outline-context.active:focus, .btn-outline-context.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-outline-context:disabled, .btn-outline-context.disabled {
  color: #ffffff;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: rgb(248, 187, 0);
  text-decoration: none;
}
.btn-link:hover {
  color: #c69600;
}
.btn-link:disabled, .btn-link.disabled {
  color: #999999;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1.25rem 2.5rem;
  font-size: calc(1.26rem + 0.12vw);
  border-radius: 0;
}
@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    font-size: 1.35rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.75rem 1.5rem;
  font-size: 1.05rem;
  border-radius: 0;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1.2rem;
  color: #000000;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #222222;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1f1f1f;
  background-color: #dddddd;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: rgb(248, 187, 0);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1.05rem;
  color: #999999;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #222222;
}

.dropdown-menu-dark {
  color: #bbbbbb;
  background-color: #555555;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #bbbbbb;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #ffffff;
  background-color: rgb(248, 187, 0);
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #bbbbbb;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(248, 187, 0);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #c69600;
}
.nav-link.disabled {
  color: #999999;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #bbbbbb;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #dddddd #dddddd #bbbbbb;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #999999;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #777777;
  background-color: #ffffff;
  border-color: #bbbbbb #bbbbbb #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: rgb(248, 187, 0);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3725rem;
  padding-bottom: 0.3725rem;
  margin-right: 4rem;
  font-size: calc(1.26rem + 0.12vw);
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .navbar-brand {
    font-size: 1.35rem;
  }
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: calc(1.26rem + 0.12vw);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  transition: box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .navbar-toggler {
    font-size: 1.35rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 2.5rem 2.5rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 2.5rem;
}

.card-header {
  padding: 2.5rem 2.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}
.card-footer {
  padding: 2.5rem 2.5rem;
  background-color: transparent;
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}
.card-header-tabs {
  margin-right: -1.25rem;
  margin-bottom: -2.5rem;
  margin-left: -1.25rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: transparent;
  border-bottom-color: transparent;
}

.card-header-pills {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-group > .card {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1.2rem;
  color: #000000;
  text-align: left;
  background-color: #ffffff;
  border: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #dfa800;
  background-color: #fef8e6;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23dfa800'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #fcdd80;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(248, 187, 0, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #999999;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #999999;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: rgb(248, 187, 0);
  background-color: transparent;
  border: 0 solid #bbbbbb;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #c69600;
  background-color: #dddddd;
  border-color: #bbbbbb;
}
.page-link:focus {
  z-index: 3;
  color: #c69600;
  background-color: #dddddd;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(248, 187, 0, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.page-item.disabled .page-link {
  color: #999999;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #bbbbbb;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
  .pagination-lg .page-link {
    font-size: 1.35rem;
  }
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.05rem;
}
.badge {
  display: inline-block;
  padding: 0.5em 0.75em;
  font-size: 1.05rem;
  font-weight: 400;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 2.5rem 2.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 7.5rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 3.125rem 2.5rem;
}

.alert-primary {
  color: #634b00;
  background-color: #fef1cc;
  border-color: #f8bb00;
}
.alert-primary .alert-link {
  color: #4f3c00;
}

.alert-secondary {
  color: #333333;
  background-color: #dddddd;
  border-color: #555555;
}
.alert-secondary .alert-link {
  color: #292929;
}

.alert-success {
  color: #112d26;
  background-color: #d2dbd9;
  border-color: #1d4b40;
}
.alert-success .alert-link {
  color: #0e241e;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #0dcaf0;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #634b00;
  background-color: #fef1cc;
  border-color: #f8bb00;
}
.alert-warning .alert-link {
  color: #4f3c00;
}

.alert-danger {
  color: #7f2f1b;
  background-color: #f6dcd5;
  border-color: #d34f2d;
}
.alert-danger .alert-link {
  color: #662616;
}

.alert-light {
  color: #5f5f5f;
  background-color: #fcfcfc;
  border-color: #eeeeee;
}
.alert-light .alert-link {
  color: #4c4c4c;
}

.alert-dark {
  color: #141414;
  background-color: lightgray;
  border-color: #222222;
}
.alert-dark .alert-link {
  color: #101010;
}

.alert-context {
  color: #666666;
  background-color: white;
  border-color: white;
}
.alert-context .alert-link {
  color: #525252;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.9rem;
  background-color: #dddddd;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: rgb(248, 187, 0);
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #777777;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #777777;
  text-decoration: none;
  background-color: #eeeeee;
}
.list-group-item-action:active {
  color: #000000;
  background-color: #dddddd;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: inherit;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #999999;
  pointer-events: none;
  background-color: transparent;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #634b00;
  background-color: #fef1cc;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #634b00;
  background-color: #e5d9b8;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #634b00;
  border-color: #634b00;
}

.list-group-item-secondary {
  color: #333333;
  background-color: #dddddd;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #333333;
  background-color: #c7c7c7;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
}

.list-group-item-success {
  color: #112d26;
  background-color: #d2dbd9;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #112d26;
  background-color: #bdc5c3;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #112d26;
  border-color: #112d26;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #634b00;
  background-color: #fef1cc;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #634b00;
  background-color: #e5d9b8;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #634b00;
  border-color: #634b00;
}

.list-group-item-danger {
  color: #7f2f1b;
  background-color: #f6dcd5;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7f2f1b;
  background-color: #ddc6c0;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #7f2f1b;
  border-color: #7f2f1b;
}

.list-group-item-light {
  color: #5f5f5f;
  background-color: #fcfcfc;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #5f5f5f;
  background-color: #e3e3e3;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #5f5f5f;
  border-color: #5f5f5f;
}

.list-group-item-dark {
  color: #141414;
  background-color: lightgray;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141414;
  background-color: #bebebe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #141414;
  border-color: #141414;
}

.list-group-item-context {
  color: #666666;
  background-color: white;
}
.list-group-item-context.list-group-item-action:hover, .list-group-item-context.list-group-item-action:focus {
  color: #666666;
  background-color: #e6e6e6;
}
.list-group-item-context.list-group-item-action.active {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(248, 187, 0, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 20px;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #999999;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2.5rem;
  border-bottom: 0 solid #dddddd;
}
.modal-header .btn-close {
  padding: 1.25rem 1.25rem;
  margin: -1.25rem -1.25rem -1.25rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.7;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2.5rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 2.25rem;
  border-top: 0 solid #dddddd;
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.05rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.05rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1.2rem;
  color: rgb(248, 187, 0);
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #000000;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.4s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2.5rem;
}
.offcanvas-header .btn-close {
  padding: 1.25rem 1.25rem;
  margin-top: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: -1.25rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.7;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 2.5rem 2.5rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: rgb(248, 187, 0);
}
.link-primary:hover, .link-primary:focus {
  color: #f9c933;
}

.link-secondary {
  color: #555555;
}
.link-secondary:hover, .link-secondary:focus {
  color: #444444;
}

.link-success {
  color: #1d4b40;
}
.link-success:hover, .link-success:focus {
  color: #173c33;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: rgb(248, 187, 0);
}
.link-warning:hover, .link-warning:focus {
  color: #f9c933;
}

.link-danger {
  color: #d34f2d;
}
.link-danger:hover, .link-danger:focus {
  color: #dc7257;
}

.link-light {
  color: #eeeeee;
}
.link-light:hover, .link-light:focus {
  color: #f1f1f1;
}

.link-dark {
  color: #222222;
}
.link-dark:hover, .link-dark:focus {
  color: #1b1b1b;
}

.link-context {
  color: #ffffff;
}
.link-context:hover, .link-context:focus {
  color: white;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-10 {
  top: 10% !important;
}

.top-20 {
  top: 20% !important;
}

.top-25 {
  top: 25% !important;
}

.top-30 {
  top: 30% !important;
}

.top-40 {
  top: 40% !important;
}

.top-50 {
  top: 50% !important;
}

.top-60 {
  top: 60% !important;
}

.top-70 {
  top: 70% !important;
}

.top-75 {
  top: 75% !important;
}

.top-80 {
  top: 80% !important;
}

.top-90 {
  top: 90% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-10 {
  bottom: 10% !important;
}

.bottom-20 {
  bottom: 20% !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-30 {
  bottom: 30% !important;
}

.bottom-40 {
  bottom: 40% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-60 {
  bottom: 60% !important;
}

.bottom-70 {
  bottom: 70% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-80 {
  bottom: 80% !important;
}

.bottom-90 {
  bottom: 90% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-10 {
  left: 10% !important;
}

.start-20 {
  left: 20% !important;
}

.start-25 {
  left: 25% !important;
}

.start-30 {
  left: 30% !important;
}

.start-40 {
  left: 40% !important;
}

.start-50 {
  left: 50% !important;
}

.start-60 {
  left: 60% !important;
}

.start-70 {
  left: 70% !important;
}

.start-75 {
  left: 75% !important;
}

.start-80 {
  left: 80% !important;
}

.start-90 {
  left: 90% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-10 {
  right: 10% !important;
}

.end-20 {
  right: 20% !important;
}

.end-25 {
  right: 25% !important;
}

.end-30 {
  right: 30% !important;
}

.end-40 {
  right: 40% !important;
}

.end-50 {
  right: 50% !important;
}

.end-60 {
  right: 60% !important;
}

.end-70 {
  right: 70% !important;
}

.end-75 {
  right: 75% !important;
}

.end-80 {
  right: 80% !important;
}

.end-90 {
  right: 90% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dddddd !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dddddd !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dddddd !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dddddd !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dddddd !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: rgb(248, 187, 0) !important;
}

.border-secondary {
  border-color: #555555 !important;
}

.border-success {
  border-color: #1d4b40 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: rgb(248, 187, 0) !important;
}

.border-danger {
  border-color: #d34f2d !important;
}

.border-light {
  border-color: #eeeeee !important;
}

.border-dark {
  border-color: #222222 !important;
}

.border-context {
  border-color: #ffffff !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.5rem !important;
}

.gap-2 {
  gap: 1rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2rem !important;
}

.gap-5 {
  gap: 2.5rem !important;
}

.gap-6 {
  gap: 3rem !important;
}

.gap-7 {
  gap: 3.5rem !important;
}

.gap-8 {
  gap: 4rem !important;
}

.gap-9 {
  gap: 4.5rem !important;
}

.gap-10 {
  gap: 5rem !important;
}

.gap-15 {
  gap: 7.5rem !important;
}

.gap-20 {
  gap: 10rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.m-7 {
  margin: 3.5rem !important;
}

.m-8 {
  margin: 4rem !important;
}

.m-9 {
  margin: 4.5rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.m-15 {
  margin: 7.5rem !important;
}

.m-20 {
  margin: 10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-5 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-6 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-7 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-8 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-9 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-10 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-15 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-20 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-7 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-8 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-9 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-15 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-20 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mt-5 {
  margin-top: 2.5rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 3.5rem !important;
}

.mt-8 {
  margin-top: 4rem !important;
}

.mt-9 {
  margin-top: 4.5rem !important;
}

.mt-10 {
  margin-top: 5rem !important;
}

.mt-15 {
  margin-top: 7.5rem !important;
}

.mt-20 {
  margin-top: 10rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.5rem !important;
}

.me-2 {
  margin-right: 1rem !important;
}

.me-3 {
  margin-right: 1.5rem !important;
}

.me-4 {
  margin-right: 2rem !important;
}

.me-5 {
  margin-right: 2.5rem !important;
}

.me-6 {
  margin-right: 3rem !important;
}

.me-7 {
  margin-right: 3.5rem !important;
}

.me-8 {
  margin-right: 4rem !important;
}

.me-9 {
  margin-right: 4.5rem !important;
}

.me-10 {
  margin-right: 5rem !important;
}

.me-15 {
  margin-right: 7.5rem !important;
}

.me-20 {
  margin-right: 10rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom: 3.5rem !important;
}

.mb-8 {
  margin-bottom: 4rem !important;
}

.mb-9 {
  margin-bottom: 4.5rem !important;
}

.mb-10 {
  margin-bottom: 5rem !important;
}

.mb-15 {
  margin-bottom: 7.5rem !important;
}

.mb-20 {
  margin-bottom: 10rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.5rem !important;
}

.ms-2 {
  margin-left: 1rem !important;
}

.ms-3 {
  margin-left: 1.5rem !important;
}

.ms-4 {
  margin-left: 2rem !important;
}

.ms-5 {
  margin-left: 2.5rem !important;
}

.ms-6 {
  margin-left: 3rem !important;
}

.ms-7 {
  margin-left: 3.5rem !important;
}

.ms-8 {
  margin-left: 4rem !important;
}

.ms-9 {
  margin-left: 4.5rem !important;
}

.ms-10 {
  margin-left: 5rem !important;
}

.ms-15 {
  margin-left: 7.5rem !important;
}

.ms-20 {
  margin-left: 10rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.p-7 {
  padding: 3.5rem !important;
}

.p-8 {
  padding: 4rem !important;
}

.p-9 {
  padding: 4.5rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.p-15 {
  padding: 7.5rem !important;
}

.p-20 {
  padding: 10rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-2 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-3 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-5 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-6 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-7 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-8 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-9 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-10 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-15 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.px-20 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-7 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-8 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-9 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-15 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.py-20 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pt-5 {
  padding-top: 2.5rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pt-7 {
  padding-top: 3.5rem !important;
}

.pt-8 {
  padding-top: 4rem !important;
}

.pt-9 {
  padding-top: 4.5rem !important;
}

.pt-10 {
  padding-top: 5rem !important;
}

.pt-15 {
  padding-top: 7.5rem !important;
}

.pt-20 {
  padding-top: 10rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.5rem !important;
}

.pe-2 {
  padding-right: 1rem !important;
}

.pe-3 {
  padding-right: 1.5rem !important;
}

.pe-4 {
  padding-right: 2rem !important;
}

.pe-5 {
  padding-right: 2.5rem !important;
}

.pe-6 {
  padding-right: 3rem !important;
}

.pe-7 {
  padding-right: 3.5rem !important;
}

.pe-8 {
  padding-right: 4rem !important;
}

.pe-9 {
  padding-right: 4.5rem !important;
}

.pe-10 {
  padding-right: 5rem !important;
}

.pe-15 {
  padding-right: 7.5rem !important;
}

.pe-20 {
  padding-right: 10rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.pb-5 {
  padding-bottom: 2.5rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-7 {
  padding-bottom: 3.5rem !important;
}

.pb-8 {
  padding-bottom: 4rem !important;
}

.pb-9 {
  padding-bottom: 4.5rem !important;
}

.pb-10 {
  padding-bottom: 5rem !important;
}

.pb-15 {
  padding-bottom: 7.5rem !important;
}

.pb-20 {
  padding-bottom: 10rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.5rem !important;
}

.ps-2 {
  padding-left: 1rem !important;
}

.ps-3 {
  padding-left: 1.5rem !important;
}

.ps-4 {
  padding-left: 2rem !important;
}

.ps-5 {
  padding-left: 2.5rem !important;
}

.ps-6 {
  padding-left: 3rem !important;
}

.ps-7 {
  padding-left: 3.5rem !important;
}

.ps-8 {
  padding-left: 4rem !important;
}

.ps-9 {
  padding-left: 4.5rem !important;
}

.ps-10 {
  padding-left: 5rem !important;
}

.ps-15 {
  padding-left: 7.5rem !important;
}

.ps-20 {
  padding-left: 10rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.485rem + 2.82vw) !important;
}

.fs-2 {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-3 {
  font-size: calc(1.389rem + 1.668vw) !important;
}

.fs-4 {
  font-size: calc(1.365rem + 1.38vw) !important;
}

.fs-5 {
  font-size: calc(1.305rem + 0.66vw) !important;
}

.fs-6 {
  font-size: 1.2rem !important;
}

.fs-sm {
  font-size: 1.05rem !important;
}

.fs-lg {
  font-size: calc(1.26rem + 0.12vw) !important;
}

.fs-base {
  font-size: 1.2rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.7 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-context {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-context-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: #000000 !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: #ffffff !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #777777 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: #4726e1 !important;
}

.text-indigo {
  --bs-text-opacity: 1;
  color: #6610f2 !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: #300d81 !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: #d63384 !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: #d34f2d !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: #f08d34 !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: rgb(248, 187, 0) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: #1d4b40 !important;
}

.text-teal {
  --bs-text-opacity: 1;
  color: #20c997 !important;
}

.text-cyan {
  --bs-text-opacity: 1;
  color: #0dcaf0 !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: #999999 !important;
}

.text-gray-dark {
  --bs-text-opacity: 1;
  color: #555555 !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-context {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-context-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: #000000 !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: #ffffff !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: #4726e1 !important;
}

.bg-indigo {
  --bs-bg-opacity: 1;
  background-color: #6610f2 !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: #300d81 !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: #d63384 !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: #d34f2d !important;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: #f08d34 !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgb(248, 187, 0) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: #1d4b40 !important;
}

.bg-teal {
  --bs-bg-opacity: 1;
  background-color: #20c997 !important;
}

.bg-cyan {
  --bs-bg-opacity: 1;
  background-color: #0dcaf0 !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: #999999 !important;
}

.bg-gray-dark {
  --bs-bg-opacity: 1;
  background-color: #555555 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.bg-opaque-blue {
  background-color: rgba(71, 38, 225, 0.1) !important;
}

.bg-opaque-indigo {
  background-color: rgba(102, 16, 242, 0.1) !important;
}

.bg-opaque-purple {
  background-color: rgba(48, 13, 129, 0.1) !important;
}

.bg-opaque-pink {
  background-color: rgba(214, 51, 132, 0.1) !important;
}

.bg-opaque-red {
  background-color: rgba(211, 79, 45, 0.1) !important;
}

.bg-opaque-orange {
  background-color: rgba(240, 141, 52, 0.1) !important;
}

.bg-opaque-yellow {
  background-color: rgba(248, 187, 0, 0.1) !important;
}

.bg-opaque-green {
  background-color: rgba(29, 75, 64, 0.1) !important;
}

.bg-opaque-teal {
  background-color: rgba(32, 201, 151, 0.1) !important;
}

.bg-opaque-cyan {
  background-color: rgba(13, 202, 240, 0.1) !important;
}

.bg-opaque-white {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bg-opaque-gray {
  background-color: rgba(153, 153, 153, 0.1) !important;
}

.bg-opaque-gray-dark {
  background-color: rgba(85, 85, 85, 0.1) !important;
}

.bg-opaque-black {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.bg-opaque-border-color {
  background-color: rgba(221, 221, 221, 0.1) !important;
}

.bg-opaque-primary {
  background-color: rgba(248, 187, 0, 0.1) !important;
}

.bg-opaque-light {
  background-color: rgba(238, 238, 238, 0.1) !important;
}

.bg-opaque-dark {
  background-color: rgba(34, 34, 34, 0.1) !important;
}

.text-primary {
  color: rgb(248, 187, 0) !important;
}

.text-primary-hover:hover {
  color: rgb(248, 187, 0) !important;
}

.text-secondary {
  color: #555555 !important;
}

.text-secondary-hover:hover {
  color: #555555 !important;
}

.text-success {
  color: #1d4b40 !important;
}

.text-success-hover:hover {
  color: #1d4b40 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-info-hover:hover {
  color: #0dcaf0 !important;
}

.text-warning {
  color: rgb(248, 187, 0) !important;
}

.text-warning-hover:hover {
  color: rgb(248, 187, 0) !important;
}

.text-danger {
  color: #d34f2d !important;
}

.text-danger-hover:hover {
  color: #d34f2d !important;
}

.text-light {
  color: #eeeeee !important;
}

.text-light-hover:hover {
  color: #eeeeee !important;
}

.text-dark {
  color: #222222 !important;
}

.text-dark-hover:hover {
  color: #222222 !important;
}

.text-context {
  color: #ffffff !important;
}

.text-context-hover:hover {
  color: #ffffff !important;
}

.text-blue {
  color: #4726e1 !important;
}

.text-blue-hover:hover {
  color: #4726e1 !important;
}

.text-indigo {
  color: #6610f2 !important;
}

.text-indigo-hover:hover {
  color: #6610f2 !important;
}

.text-purple {
  color: #300d81 !important;
}

.text-purple-hover:hover {
  color: #300d81 !important;
}

.text-pink {
  color: #d63384 !important;
}

.text-pink-hover:hover {
  color: #d63384 !important;
}

.text-red {
  color: #d34f2d !important;
}

.text-red-hover:hover {
  color: #d34f2d !important;
}

.text-orange {
  color: #f08d34 !important;
}

.text-orange-hover:hover {
  color: #f08d34 !important;
}

.text-yellow {
  color: rgb(248, 187, 0) !important;
}

.text-yellow-hover:hover {
  color: rgb(248, 187, 0) !important;
}

.text-green {
  color: #1d4b40 !important;
}

.text-green-hover:hover {
  color: #1d4b40 !important;
}

.text-teal {
  color: #20c997 !important;
}

.text-teal-hover:hover {
  color: #20c997 !important;
}

.text-cyan {
  color: #0dcaf0 !important;
}

.text-cyan-hover:hover {
  color: #0dcaf0 !important;
}

.text-white {
  color: #ffffff !important;
}

.text-white-hover:hover {
  color: #ffffff !important;
}

.text-gray {
  color: #999999 !important;
}

.text-gray-hover:hover {
  color: #999999 !important;
}

.text-gray-dark {
  color: #555555 !important;
}

.text-gray-dark-hover:hover {
  color: #555555 !important;
}

.text-black {
  color: #000000 !important;
}

.text-black-hover:hover {
  color: #000000 !important;
}

.text-gold {
  color: #807764 !important;
}

.text-gold-hover:hover {
  color: #807764 !important;
}

.text-border-color {
  color: #dddddd !important;
}

.text-border-color-hover:hover {
  color: #dddddd !important;
}

.bg-primary {
  background-color: rgb(248, 187, 0) !important;
}

.bg-primary-hover:hover {
  background-color: rgb(248, 187, 0) !important;
}

.bg-secondary {
  background-color: #555555 !important;
}

.bg-secondary-hover:hover {
  background-color: #555555 !important;
}

.bg-success {
  background-color: #1d4b40 !important;
}

.bg-success-hover:hover {
  background-color: #1d4b40 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-info-hover:hover {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: rgb(248, 187, 0) !important;
}

.bg-warning-hover:hover {
  background-color: rgb(248, 187, 0) !important;
}

.bg-danger {
  background-color: #d34f2d !important;
}

.bg-danger-hover:hover {
  background-color: #d34f2d !important;
}

.bg-light {
  background-color: #eeeeee !important;
}

.bg-light-hover:hover {
  background-color: #eeeeee !important;
}

.bg-dark {
  background-color: #222222 !important;
}

.bg-dark-hover:hover {
  background-color: #222222 !important;
}

.bg-context {
  background-color: #ffffff !important;
}

.bg-context-hover:hover {
  background-color: #ffffff !important;
}

.bg-blue {
  background-color: #4726e1 !important;
}

.bg-blue-hover:hover {
  background-color: #4726e1 !important;
}

.bg-indigo {
  background-color: #6610f2 !important;
}

.bg-indigo-hover:hover {
  background-color: #6610f2 !important;
}

.bg-purple {
  background-color: #300d81 !important;
}

.bg-purple-hover:hover {
  background-color: #300d81 !important;
}

.bg-pink {
  background-color: #d63384 !important;
}

.bg-pink-hover:hover {
  background-color: #d63384 !important;
}

.bg-red {
  background-color: #d34f2d !important;
}

.bg-red-hover:hover {
  background-color: #d34f2d !important;
}

.bg-orange {
  background-color: #f08d34 !important;
}

.bg-orange-hover:hover {
  background-color: #f08d34 !important;
}

.bg-yellow {
  background-color: rgb(248, 187, 0) !important;
}

.bg-yellow-hover:hover {
  background-color: rgb(248, 187, 0) !important;
}

.bg-green {
  background-color: #1d4b40 !important;
}

.bg-green-hover:hover {
  background-color: #1d4b40 !important;
}

.bg-teal {
  background-color: #20c997 !important;
}

.bg-teal-hover:hover {
  background-color: #20c997 !important;
}

.bg-cyan {
  background-color: #0dcaf0 !important;
}

.bg-cyan-hover:hover {
  background-color: #0dcaf0 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-white-hover:hover {
  background-color: #ffffff !important;
}

.bg-gray {
  background-color: #999999 !important;
}

.bg-gray-hover:hover {
  background-color: #999999 !important;
}

.bg-gray-dark {
  background-color: #555555 !important;
}

.bg-gray-dark-hover:hover {
  background-color: #555555 !important;
}

.bg-black {
  background-color: #000000 !important;
}

.bg-black-hover:hover {
  background-color: #000000 !important;
}

.bg-gold {
  background-color: #807764 !important;
}

.bg-gold-hover:hover {
  background-color: #807764 !important;
}

.bg-border-color {
  background-color: #dddddd !important;
}

.bg-border-color-hover:hover {
  background-color: #dddddd !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.5rem !important;
  }

  .gap-sm-2 {
    gap: 1rem !important;
  }

  .gap-sm-3 {
    gap: 1.5rem !important;
  }

  .gap-sm-4 {
    gap: 2rem !important;
  }

  .gap-sm-5 {
    gap: 2.5rem !important;
  }

  .gap-sm-6 {
    gap: 3rem !important;
  }

  .gap-sm-7 {
    gap: 3.5rem !important;
  }

  .gap-sm-8 {
    gap: 4rem !important;
  }

  .gap-sm-9 {
    gap: 4.5rem !important;
  }

  .gap-sm-10 {
    gap: 5rem !important;
  }

  .gap-sm-15 {
    gap: 7.5rem !important;
  }

  .gap-sm-20 {
    gap: 10rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.5rem !important;
  }

  .m-sm-2 {
    margin: 1rem !important;
  }

  .m-sm-3 {
    margin: 1.5rem !important;
  }

  .m-sm-4 {
    margin: 2rem !important;
  }

  .m-sm-5 {
    margin: 2.5rem !important;
  }

  .m-sm-6 {
    margin: 3rem !important;
  }

  .m-sm-7 {
    margin: 3.5rem !important;
  }

  .m-sm-8 {
    margin: 4rem !important;
  }

  .m-sm-9 {
    margin: 4.5rem !important;
  }

  .m-sm-10 {
    margin: 5rem !important;
  }

  .m-sm-15 {
    margin: 7.5rem !important;
  }

  .m-sm-20 {
    margin: 10rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-9 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-15 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-sm-20 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-sm-20 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-2 {
    margin-top: 1rem !important;
  }

  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-4 {
    margin-top: 2rem !important;
  }

  .mt-sm-5 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-6 {
    margin-top: 3rem !important;
  }

  .mt-sm-7 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-8 {
    margin-top: 4rem !important;
  }

  .mt-sm-9 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-10 {
    margin-top: 5rem !important;
  }

  .mt-sm-15 {
    margin-top: 7.5rem !important;
  }

  .mt-sm-20 {
    margin-top: 10rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.5rem !important;
  }

  .me-sm-2 {
    margin-right: 1rem !important;
  }

  .me-sm-3 {
    margin-right: 1.5rem !important;
  }

  .me-sm-4 {
    margin-right: 2rem !important;
  }

  .me-sm-5 {
    margin-right: 2.5rem !important;
  }

  .me-sm-6 {
    margin-right: 3rem !important;
  }

  .me-sm-7 {
    margin-right: 3.5rem !important;
  }

  .me-sm-8 {
    margin-right: 4rem !important;
  }

  .me-sm-9 {
    margin-right: 4.5rem !important;
  }

  .me-sm-10 {
    margin-right: 5rem !important;
  }

  .me-sm-15 {
    margin-right: 7.5rem !important;
  }

  .me-sm-20 {
    margin-right: 10rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 7.5rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-2 {
    margin-left: 1rem !important;
  }

  .ms-sm-3 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-4 {
    margin-left: 2rem !important;
  }

  .ms-sm-5 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-6 {
    margin-left: 3rem !important;
  }

  .ms-sm-7 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-8 {
    margin-left: 4rem !important;
  }

  .ms-sm-9 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-10 {
    margin-left: 5rem !important;
  }

  .ms-sm-15 {
    margin-left: 7.5rem !important;
  }

  .ms-sm-20 {
    margin-left: 10rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.5rem !important;
  }

  .p-sm-2 {
    padding: 1rem !important;
  }

  .p-sm-3 {
    padding: 1.5rem !important;
  }

  .p-sm-4 {
    padding: 2rem !important;
  }

  .p-sm-5 {
    padding: 2.5rem !important;
  }

  .p-sm-6 {
    padding: 3rem !important;
  }

  .p-sm-7 {
    padding: 3.5rem !important;
  }

  .p-sm-8 {
    padding: 4rem !important;
  }

  .p-sm-9 {
    padding: 4.5rem !important;
  }

  .p-sm-10 {
    padding: 5rem !important;
  }

  .p-sm-15 {
    padding: 7.5rem !important;
  }

  .p-sm-20 {
    padding: 10rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-9 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-sm-15 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-sm-20 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-sm-20 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-2 {
    padding-top: 1rem !important;
  }

  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-4 {
    padding-top: 2rem !important;
  }

  .pt-sm-5 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-6 {
    padding-top: 3rem !important;
  }

  .pt-sm-7 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-8 {
    padding-top: 4rem !important;
  }

  .pt-sm-9 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-10 {
    padding-top: 5rem !important;
  }

  .pt-sm-15 {
    padding-top: 7.5rem !important;
  }

  .pt-sm-20 {
    padding-top: 10rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-2 {
    padding-right: 1rem !important;
  }

  .pe-sm-3 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-4 {
    padding-right: 2rem !important;
  }

  .pe-sm-5 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-6 {
    padding-right: 3rem !important;
  }

  .pe-sm-7 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-8 {
    padding-right: 4rem !important;
  }

  .pe-sm-9 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-10 {
    padding-right: 5rem !important;
  }

  .pe-sm-15 {
    padding-right: 7.5rem !important;
  }

  .pe-sm-20 {
    padding-right: 10rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 7.5rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 10rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-2 {
    padding-left: 1rem !important;
  }

  .ps-sm-3 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-4 {
    padding-left: 2rem !important;
  }

  .ps-sm-5 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-6 {
    padding-left: 3rem !important;
  }

  .ps-sm-7 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-8 {
    padding-left: 4rem !important;
  }

  .ps-sm-9 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-10 {
    padding-left: 5rem !important;
  }

  .ps-sm-15 {
    padding-left: 7.5rem !important;
  }

  .ps-sm-20 {
    padding-left: 10rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.5rem !important;
  }

  .gap-md-2 {
    gap: 1rem !important;
  }

  .gap-md-3 {
    gap: 1.5rem !important;
  }

  .gap-md-4 {
    gap: 2rem !important;
  }

  .gap-md-5 {
    gap: 2.5rem !important;
  }

  .gap-md-6 {
    gap: 3rem !important;
  }

  .gap-md-7 {
    gap: 3.5rem !important;
  }

  .gap-md-8 {
    gap: 4rem !important;
  }

  .gap-md-9 {
    gap: 4.5rem !important;
  }

  .gap-md-10 {
    gap: 5rem !important;
  }

  .gap-md-15 {
    gap: 7.5rem !important;
  }

  .gap-md-20 {
    gap: 10rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.5rem !important;
  }

  .m-md-2 {
    margin: 1rem !important;
  }

  .m-md-3 {
    margin: 1.5rem !important;
  }

  .m-md-4 {
    margin: 2rem !important;
  }

  .m-md-5 {
    margin: 2.5rem !important;
  }

  .m-md-6 {
    margin: 3rem !important;
  }

  .m-md-7 {
    margin: 3.5rem !important;
  }

  .m-md-8 {
    margin: 4rem !important;
  }

  .m-md-9 {
    margin: 4.5rem !important;
  }

  .m-md-10 {
    margin: 5rem !important;
  }

  .m-md-15 {
    margin: 7.5rem !important;
  }

  .m-md-20 {
    margin: 10rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-9 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-15 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-md-20 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-md-20 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.5rem !important;
  }

  .mt-md-2 {
    margin-top: 1rem !important;
  }

  .mt-md-3 {
    margin-top: 1.5rem !important;
  }

  .mt-md-4 {
    margin-top: 2rem !important;
  }

  .mt-md-5 {
    margin-top: 2.5rem !important;
  }

  .mt-md-6 {
    margin-top: 3rem !important;
  }

  .mt-md-7 {
    margin-top: 3.5rem !important;
  }

  .mt-md-8 {
    margin-top: 4rem !important;
  }

  .mt-md-9 {
    margin-top: 4.5rem !important;
  }

  .mt-md-10 {
    margin-top: 5rem !important;
  }

  .mt-md-15 {
    margin-top: 7.5rem !important;
  }

  .mt-md-20 {
    margin-top: 10rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.5rem !important;
  }

  .me-md-2 {
    margin-right: 1rem !important;
  }

  .me-md-3 {
    margin-right: 1.5rem !important;
  }

  .me-md-4 {
    margin-right: 2rem !important;
  }

  .me-md-5 {
    margin-right: 2.5rem !important;
  }

  .me-md-6 {
    margin-right: 3rem !important;
  }

  .me-md-7 {
    margin-right: 3.5rem !important;
  }

  .me-md-8 {
    margin-right: 4rem !important;
  }

  .me-md-9 {
    margin-right: 4.5rem !important;
  }

  .me-md-10 {
    margin-right: 5rem !important;
  }

  .me-md-15 {
    margin-right: 7.5rem !important;
  }

  .me-md-20 {
    margin-right: 10rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-2 {
    margin-bottom: 1rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-4 {
    margin-bottom: 2rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-6 {
    margin-bottom: 3rem !important;
  }

  .mb-md-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-8 {
    margin-bottom: 4rem !important;
  }

  .mb-md-9 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-10 {
    margin-bottom: 5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 7.5rem !important;
  }

  .mb-md-20 {
    margin-bottom: 10rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.5rem !important;
  }

  .ms-md-2 {
    margin-left: 1rem !important;
  }

  .ms-md-3 {
    margin-left: 1.5rem !important;
  }

  .ms-md-4 {
    margin-left: 2rem !important;
  }

  .ms-md-5 {
    margin-left: 2.5rem !important;
  }

  .ms-md-6 {
    margin-left: 3rem !important;
  }

  .ms-md-7 {
    margin-left: 3.5rem !important;
  }

  .ms-md-8 {
    margin-left: 4rem !important;
  }

  .ms-md-9 {
    margin-left: 4.5rem !important;
  }

  .ms-md-10 {
    margin-left: 5rem !important;
  }

  .ms-md-15 {
    margin-left: 7.5rem !important;
  }

  .ms-md-20 {
    margin-left: 10rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.5rem !important;
  }

  .p-md-2 {
    padding: 1rem !important;
  }

  .p-md-3 {
    padding: 1.5rem !important;
  }

  .p-md-4 {
    padding: 2rem !important;
  }

  .p-md-5 {
    padding: 2.5rem !important;
  }

  .p-md-6 {
    padding: 3rem !important;
  }

  .p-md-7 {
    padding: 3.5rem !important;
  }

  .p-md-8 {
    padding: 4rem !important;
  }

  .p-md-9 {
    padding: 4.5rem !important;
  }

  .p-md-10 {
    padding: 5rem !important;
  }

  .p-md-15 {
    padding: 7.5rem !important;
  }

  .p-md-20 {
    padding: 10rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-9 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-md-15 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-md-20 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-md-20 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.5rem !important;
  }

  .pt-md-2 {
    padding-top: 1rem !important;
  }

  .pt-md-3 {
    padding-top: 1.5rem !important;
  }

  .pt-md-4 {
    padding-top: 2rem !important;
  }

  .pt-md-5 {
    padding-top: 2.5rem !important;
  }

  .pt-md-6 {
    padding-top: 3rem !important;
  }

  .pt-md-7 {
    padding-top: 3.5rem !important;
  }

  .pt-md-8 {
    padding-top: 4rem !important;
  }

  .pt-md-9 {
    padding-top: 4.5rem !important;
  }

  .pt-md-10 {
    padding-top: 5rem !important;
  }

  .pt-md-15 {
    padding-top: 7.5rem !important;
  }

  .pt-md-20 {
    padding-top: 10rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.5rem !important;
  }

  .pe-md-2 {
    padding-right: 1rem !important;
  }

  .pe-md-3 {
    padding-right: 1.5rem !important;
  }

  .pe-md-4 {
    padding-right: 2rem !important;
  }

  .pe-md-5 {
    padding-right: 2.5rem !important;
  }

  .pe-md-6 {
    padding-right: 3rem !important;
  }

  .pe-md-7 {
    padding-right: 3.5rem !important;
  }

  .pe-md-8 {
    padding-right: 4rem !important;
  }

  .pe-md-9 {
    padding-right: 4.5rem !important;
  }

  .pe-md-10 {
    padding-right: 5rem !important;
  }

  .pe-md-15 {
    padding-right: 7.5rem !important;
  }

  .pe-md-20 {
    padding-right: 10rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-2 {
    padding-bottom: 1rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-4 {
    padding-bottom: 2rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-6 {
    padding-bottom: 3rem !important;
  }

  .pb-md-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-8 {
    padding-bottom: 4rem !important;
  }

  .pb-md-9 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-10 {
    padding-bottom: 5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 7.5rem !important;
  }

  .pb-md-20 {
    padding-bottom: 10rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.5rem !important;
  }

  .ps-md-2 {
    padding-left: 1rem !important;
  }

  .ps-md-3 {
    padding-left: 1.5rem !important;
  }

  .ps-md-4 {
    padding-left: 2rem !important;
  }

  .ps-md-5 {
    padding-left: 2.5rem !important;
  }

  .ps-md-6 {
    padding-left: 3rem !important;
  }

  .ps-md-7 {
    padding-left: 3.5rem !important;
  }

  .ps-md-8 {
    padding-left: 4rem !important;
  }

  .ps-md-9 {
    padding-left: 4.5rem !important;
  }

  .ps-md-10 {
    padding-left: 5rem !important;
  }

  .ps-md-15 {
    padding-left: 7.5rem !important;
  }

  .ps-md-20 {
    padding-left: 10rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.5rem !important;
  }

  .gap-lg-2 {
    gap: 1rem !important;
  }

  .gap-lg-3 {
    gap: 1.5rem !important;
  }

  .gap-lg-4 {
    gap: 2rem !important;
  }

  .gap-lg-5 {
    gap: 2.5rem !important;
  }

  .gap-lg-6 {
    gap: 3rem !important;
  }

  .gap-lg-7 {
    gap: 3.5rem !important;
  }

  .gap-lg-8 {
    gap: 4rem !important;
  }

  .gap-lg-9 {
    gap: 4.5rem !important;
  }

  .gap-lg-10 {
    gap: 5rem !important;
  }

  .gap-lg-15 {
    gap: 7.5rem !important;
  }

  .gap-lg-20 {
    gap: 10rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.5rem !important;
  }

  .m-lg-2 {
    margin: 1rem !important;
  }

  .m-lg-3 {
    margin: 1.5rem !important;
  }

  .m-lg-4 {
    margin: 2rem !important;
  }

  .m-lg-5 {
    margin: 2.5rem !important;
  }

  .m-lg-6 {
    margin: 3rem !important;
  }

  .m-lg-7 {
    margin: 3.5rem !important;
  }

  .m-lg-8 {
    margin: 4rem !important;
  }

  .m-lg-9 {
    margin: 4.5rem !important;
  }

  .m-lg-10 {
    margin: 5rem !important;
  }

  .m-lg-15 {
    margin: 7.5rem !important;
  }

  .m-lg-20 {
    margin: 10rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-9 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-15 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-lg-20 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-lg-20 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-2 {
    margin-top: 1rem !important;
  }

  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-4 {
    margin-top: 2rem !important;
  }

  .mt-lg-5 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-6 {
    margin-top: 3rem !important;
  }

  .mt-lg-7 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-8 {
    margin-top: 4rem !important;
  }

  .mt-lg-9 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-10 {
    margin-top: 5rem !important;
  }

  .mt-lg-15 {
    margin-top: 7.5rem !important;
  }

  .mt-lg-20 {
    margin-top: 10rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.5rem !important;
  }

  .me-lg-2 {
    margin-right: 1rem !important;
  }

  .me-lg-3 {
    margin-right: 1.5rem !important;
  }

  .me-lg-4 {
    margin-right: 2rem !important;
  }

  .me-lg-5 {
    margin-right: 2.5rem !important;
  }

  .me-lg-6 {
    margin-right: 3rem !important;
  }

  .me-lg-7 {
    margin-right: 3.5rem !important;
  }

  .me-lg-8 {
    margin-right: 4rem !important;
  }

  .me-lg-9 {
    margin-right: 4.5rem !important;
  }

  .me-lg-10 {
    margin-right: 5rem !important;
  }

  .me-lg-15 {
    margin-right: 7.5rem !important;
  }

  .me-lg-20 {
    margin-right: 10rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 7.5rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-2 {
    margin-left: 1rem !important;
  }

  .ms-lg-3 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-4 {
    margin-left: 2rem !important;
  }

  .ms-lg-5 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-6 {
    margin-left: 3rem !important;
  }

  .ms-lg-7 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-8 {
    margin-left: 4rem !important;
  }

  .ms-lg-9 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-10 {
    margin-left: 5rem !important;
  }

  .ms-lg-15 {
    margin-left: 7.5rem !important;
  }

  .ms-lg-20 {
    margin-left: 10rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.5rem !important;
  }

  .p-lg-2 {
    padding: 1rem !important;
  }

  .p-lg-3 {
    padding: 1.5rem !important;
  }

  .p-lg-4 {
    padding: 2rem !important;
  }

  .p-lg-5 {
    padding: 2.5rem !important;
  }

  .p-lg-6 {
    padding: 3rem !important;
  }

  .p-lg-7 {
    padding: 3.5rem !important;
  }

  .p-lg-8 {
    padding: 4rem !important;
  }

  .p-lg-9 {
    padding: 4.5rem !important;
  }

  .p-lg-10 {
    padding: 5rem !important;
  }

  .p-lg-15 {
    padding: 7.5rem !important;
  }

  .p-lg-20 {
    padding: 10rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-9 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-lg-15 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-lg-20 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-lg-20 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-2 {
    padding-top: 1rem !important;
  }

  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-4 {
    padding-top: 2rem !important;
  }

  .pt-lg-5 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-6 {
    padding-top: 3rem !important;
  }

  .pt-lg-7 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-8 {
    padding-top: 4rem !important;
  }

  .pt-lg-9 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-10 {
    padding-top: 5rem !important;
  }

  .pt-lg-15 {
    padding-top: 7.5rem !important;
  }

  .pt-lg-20 {
    padding-top: 10rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-2 {
    padding-right: 1rem !important;
  }

  .pe-lg-3 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-4 {
    padding-right: 2rem !important;
  }

  .pe-lg-5 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-6 {
    padding-right: 3rem !important;
  }

  .pe-lg-7 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-8 {
    padding-right: 4rem !important;
  }

  .pe-lg-9 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-10 {
    padding-right: 5rem !important;
  }

  .pe-lg-15 {
    padding-right: 7.5rem !important;
  }

  .pe-lg-20 {
    padding-right: 10rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 7.5rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 10rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-2 {
    padding-left: 1rem !important;
  }

  .ps-lg-3 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-4 {
    padding-left: 2rem !important;
  }

  .ps-lg-5 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-6 {
    padding-left: 3rem !important;
  }

  .ps-lg-7 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-8 {
    padding-left: 4rem !important;
  }

  .ps-lg-9 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-10 {
    padding-left: 5rem !important;
  }

  .ps-lg-15 {
    padding-left: 7.5rem !important;
  }

  .ps-lg-20 {
    padding-left: 10rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.5rem !important;
  }

  .gap-xl-2 {
    gap: 1rem !important;
  }

  .gap-xl-3 {
    gap: 1.5rem !important;
  }

  .gap-xl-4 {
    gap: 2rem !important;
  }

  .gap-xl-5 {
    gap: 2.5rem !important;
  }

  .gap-xl-6 {
    gap: 3rem !important;
  }

  .gap-xl-7 {
    gap: 3.5rem !important;
  }

  .gap-xl-8 {
    gap: 4rem !important;
  }

  .gap-xl-9 {
    gap: 4.5rem !important;
  }

  .gap-xl-10 {
    gap: 5rem !important;
  }

  .gap-xl-15 {
    gap: 7.5rem !important;
  }

  .gap-xl-20 {
    gap: 10rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.5rem !important;
  }

  .m-xl-2 {
    margin: 1rem !important;
  }

  .m-xl-3 {
    margin: 1.5rem !important;
  }

  .m-xl-4 {
    margin: 2rem !important;
  }

  .m-xl-5 {
    margin: 2.5rem !important;
  }

  .m-xl-6 {
    margin: 3rem !important;
  }

  .m-xl-7 {
    margin: 3.5rem !important;
  }

  .m-xl-8 {
    margin: 4rem !important;
  }

  .m-xl-9 {
    margin: 4.5rem !important;
  }

  .m-xl-10 {
    margin: 5rem !important;
  }

  .m-xl-15 {
    margin: 7.5rem !important;
  }

  .m-xl-20 {
    margin: 10rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-9 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-15 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-xl-20 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-xl-20 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-2 {
    margin-top: 1rem !important;
  }

  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-4 {
    margin-top: 2rem !important;
  }

  .mt-xl-5 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-6 {
    margin-top: 3rem !important;
  }

  .mt-xl-7 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-8 {
    margin-top: 4rem !important;
  }

  .mt-xl-9 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-10 {
    margin-top: 5rem !important;
  }

  .mt-xl-15 {
    margin-top: 7.5rem !important;
  }

  .mt-xl-20 {
    margin-top: 10rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.5rem !important;
  }

  .me-xl-2 {
    margin-right: 1rem !important;
  }

  .me-xl-3 {
    margin-right: 1.5rem !important;
  }

  .me-xl-4 {
    margin-right: 2rem !important;
  }

  .me-xl-5 {
    margin-right: 2.5rem !important;
  }

  .me-xl-6 {
    margin-right: 3rem !important;
  }

  .me-xl-7 {
    margin-right: 3.5rem !important;
  }

  .me-xl-8 {
    margin-right: 4rem !important;
  }

  .me-xl-9 {
    margin-right: 4.5rem !important;
  }

  .me-xl-10 {
    margin-right: 5rem !important;
  }

  .me-xl-15 {
    margin-right: 7.5rem !important;
  }

  .me-xl-20 {
    margin-right: 10rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 7.5rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-2 {
    margin-left: 1rem !important;
  }

  .ms-xl-3 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-4 {
    margin-left: 2rem !important;
  }

  .ms-xl-5 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-6 {
    margin-left: 3rem !important;
  }

  .ms-xl-7 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-8 {
    margin-left: 4rem !important;
  }

  .ms-xl-9 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-10 {
    margin-left: 5rem !important;
  }

  .ms-xl-15 {
    margin-left: 7.5rem !important;
  }

  .ms-xl-20 {
    margin-left: 10rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.5rem !important;
  }

  .p-xl-2 {
    padding: 1rem !important;
  }

  .p-xl-3 {
    padding: 1.5rem !important;
  }

  .p-xl-4 {
    padding: 2rem !important;
  }

  .p-xl-5 {
    padding: 2.5rem !important;
  }

  .p-xl-6 {
    padding: 3rem !important;
  }

  .p-xl-7 {
    padding: 3.5rem !important;
  }

  .p-xl-8 {
    padding: 4rem !important;
  }

  .p-xl-9 {
    padding: 4.5rem !important;
  }

  .p-xl-10 {
    padding: 5rem !important;
  }

  .p-xl-15 {
    padding: 7.5rem !important;
  }

  .p-xl-20 {
    padding: 10rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-9 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xl-15 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-xl-20 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-xl-20 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-2 {
    padding-top: 1rem !important;
  }

  .pt-xl-3 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-4 {
    padding-top: 2rem !important;
  }

  .pt-xl-5 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-6 {
    padding-top: 3rem !important;
  }

  .pt-xl-7 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-8 {
    padding-top: 4rem !important;
  }

  .pt-xl-9 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-10 {
    padding-top: 5rem !important;
  }

  .pt-xl-15 {
    padding-top: 7.5rem !important;
  }

  .pt-xl-20 {
    padding-top: 10rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-2 {
    padding-right: 1rem !important;
  }

  .pe-xl-3 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-4 {
    padding-right: 2rem !important;
  }

  .pe-xl-5 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-6 {
    padding-right: 3rem !important;
  }

  .pe-xl-7 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-8 {
    padding-right: 4rem !important;
  }

  .pe-xl-9 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-10 {
    padding-right: 5rem !important;
  }

  .pe-xl-15 {
    padding-right: 7.5rem !important;
  }

  .pe-xl-20 {
    padding-right: 10rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 7.5rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 10rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-2 {
    padding-left: 1rem !important;
  }

  .ps-xl-3 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-4 {
    padding-left: 2rem !important;
  }

  .ps-xl-5 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-6 {
    padding-left: 3rem !important;
  }

  .ps-xl-7 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-8 {
    padding-left: 4rem !important;
  }

  .ps-xl-9 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-10 {
    padding-left: 5rem !important;
  }

  .ps-xl-15 {
    padding-left: 7.5rem !important;
  }

  .ps-xl-20 {
    padding-left: 10rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.5rem !important;
  }

  .gap-xxl-2 {
    gap: 1rem !important;
  }

  .gap-xxl-3 {
    gap: 1.5rem !important;
  }

  .gap-xxl-4 {
    gap: 2rem !important;
  }

  .gap-xxl-5 {
    gap: 2.5rem !important;
  }

  .gap-xxl-6 {
    gap: 3rem !important;
  }

  .gap-xxl-7 {
    gap: 3.5rem !important;
  }

  .gap-xxl-8 {
    gap: 4rem !important;
  }

  .gap-xxl-9 {
    gap: 4.5rem !important;
  }

  .gap-xxl-10 {
    gap: 5rem !important;
  }

  .gap-xxl-15 {
    gap: 7.5rem !important;
  }

  .gap-xxl-20 {
    gap: 10rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.5rem !important;
  }

  .m-xxl-2 {
    margin: 1rem !important;
  }

  .m-xxl-3 {
    margin: 1.5rem !important;
  }

  .m-xxl-4 {
    margin: 2rem !important;
  }

  .m-xxl-5 {
    margin: 2.5rem !important;
  }

  .m-xxl-6 {
    margin: 3rem !important;
  }

  .m-xxl-7 {
    margin: 3.5rem !important;
  }

  .m-xxl-8 {
    margin: 4rem !important;
  }

  .m-xxl-9 {
    margin: 4.5rem !important;
  }

  .m-xxl-10 {
    margin: 5rem !important;
  }

  .m-xxl-15 {
    margin: 7.5rem !important;
  }

  .m-xxl-20 {
    margin: 10rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-4 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-9 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-15 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-xxl-20 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-xxl-20 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-2 {
    margin-top: 1rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-4 {
    margin-top: 2rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-6 {
    margin-top: 3rem !important;
  }

  .mt-xxl-7 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-8 {
    margin-top: 4rem !important;
  }

  .mt-xxl-9 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-10 {
    margin-top: 5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 7.5rem !important;
  }

  .mt-xxl-20 {
    margin-top: 10rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-2 {
    margin-right: 1rem !important;
  }

  .me-xxl-3 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-4 {
    margin-right: 2rem !important;
  }

  .me-xxl-5 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-6 {
    margin-right: 3rem !important;
  }

  .me-xxl-7 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-8 {
    margin-right: 4rem !important;
  }

  .me-xxl-9 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-10 {
    margin-right: 5rem !important;
  }

  .me-xxl-15 {
    margin-right: 7.5rem !important;
  }

  .me-xxl-20 {
    margin-right: 10rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 7.5rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-2 {
    margin-left: 1rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-4 {
    margin-left: 2rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-6 {
    margin-left: 3rem !important;
  }

  .ms-xxl-7 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-8 {
    margin-left: 4rem !important;
  }

  .ms-xxl-9 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-10 {
    margin-left: 5rem !important;
  }

  .ms-xxl-15 {
    margin-left: 7.5rem !important;
  }

  .ms-xxl-20 {
    margin-left: 10rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.5rem !important;
  }

  .p-xxl-2 {
    padding: 1rem !important;
  }

  .p-xxl-3 {
    padding: 1.5rem !important;
  }

  .p-xxl-4 {
    padding: 2rem !important;
  }

  .p-xxl-5 {
    padding: 2.5rem !important;
  }

  .p-xxl-6 {
    padding: 3rem !important;
  }

  .p-xxl-7 {
    padding: 3.5rem !important;
  }

  .p-xxl-8 {
    padding: 4rem !important;
  }

  .p-xxl-9 {
    padding: 4.5rem !important;
  }

  .p-xxl-10 {
    padding: 5rem !important;
  }

  .p-xxl-15 {
    padding: 7.5rem !important;
  }

  .p-xxl-20 {
    padding: 10rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-4 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-9 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xxl-15 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-xxl-20 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xxl-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-xxl-20 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-2 {
    padding-top: 1rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-4 {
    padding-top: 2rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-6 {
    padding-top: 3rem !important;
  }

  .pt-xxl-7 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-8 {
    padding-top: 4rem !important;
  }

  .pt-xxl-9 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-10 {
    padding-top: 5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 7.5rem !important;
  }

  .pt-xxl-20 {
    padding-top: 10rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-2 {
    padding-right: 1rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-4 {
    padding-right: 2rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-6 {
    padding-right: 3rem !important;
  }

  .pe-xxl-7 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-8 {
    padding-right: 4rem !important;
  }

  .pe-xxl-9 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-10 {
    padding-right: 5rem !important;
  }

  .pe-xxl-15 {
    padding-right: 7.5rem !important;
  }

  .pe-xxl-20 {
    padding-right: 10rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 7.5rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 10rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-2 {
    padding-left: 1rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-4 {
    padding-left: 2rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-6 {
    padding-left: 3rem !important;
  }

  .ps-xxl-7 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-8 {
    padding-left: 4rem !important;
  }

  .ps-xxl-9 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-10 {
    padding-left: 5rem !important;
  }

  .ps-xxl-15 {
    padding-left: 7.5rem !important;
  }

  .ps-xxl-20 {
    padding-left: 10rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3.6rem !important;
  }

  .fs-2 {
    font-size: 3rem !important;
  }

  .fs-3 {
    font-size: 2.64rem !important;
  }

  .fs-4 {
    font-size: 2.4rem !important;
  }

  .fs-5 {
    font-size: 1.8rem !important;
  }

  .fs-lg {
    font-size: 1.35rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.icon-box {
  position: relative;
  display: inline-flex;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}
.icon-box[class*=-sm] {
  min-width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.35rem;
}
.icon-box[class*=-lg] {
  min-width: 4.5rem;
  width: 4.5rem;
  height: 4.5rem;
  font-size: 1.5rem;
}

.bi {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
}

.equal-16-9 {
  position: relative;
  display: block;
  overflow: hidden;
  height: auto !important;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.equal-16-9::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.equal-16-9 > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.equal-16-10 {
  position: relative;
  display: block;
  overflow: hidden;
  height: auto !important;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.equal-16-10::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 62.5%;
}
.equal-16-10 > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.equal-4-3 {
  position: relative;
  display: block;
  overflow: hidden;
  height: auto !important;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.equal-4-3::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 75%;
}
.equal-4-3 > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.equal-3-4 {
  position: relative;
  display: block;
  overflow: hidden;
  height: auto !important;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.equal-3-4::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 133.3333%;
}
.equal-3-4 > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.equal-2-1 {
  position: relative;
  display: block;
  overflow: hidden;
  height: auto !important;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.equal-2-1::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 50%;
}
.equal-2-1 > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.equal-1-2 {
  position: relative;
  display: block;
  overflow: hidden;
  height: auto !important;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.equal-1-2::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 200%;
}
.equal-1-2 > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.equal-1-1 {
  position: relative;
  display: block;
  overflow: hidden;
  height: auto !important;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.equal-1-1::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.equal-1-1 > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 576px) {
  .equal-sm-16-9 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-sm-16-9::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
  }
  .equal-sm-16-9 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-sm-16-10 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-sm-16-10::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 62.5%;
  }
  .equal-sm-16-10 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-sm-4-3 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-sm-4-3::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 75%;
  }
  .equal-sm-4-3 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-sm-3-4 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-sm-3-4::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 133.3333%;
  }
  .equal-sm-3-4 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-sm-2-1 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-sm-2-1::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 50%;
  }
  .equal-sm-2-1 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-sm-1-2 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-sm-1-2::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 200%;
  }
  .equal-sm-1-2 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-sm-1-1 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-sm-1-1::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
  .equal-sm-1-1 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 768px) {
  .equal-md-16-9 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-md-16-9::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
  }
  .equal-md-16-9 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-md-16-10 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-md-16-10::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 62.5%;
  }
  .equal-md-16-10 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-md-4-3 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-md-4-3::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 75%;
  }
  .equal-md-4-3 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-md-3-4 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-md-3-4::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 133.3333%;
  }
  .equal-md-3-4 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-md-2-1 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-md-2-1::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 50%;
  }
  .equal-md-2-1 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-md-1-2 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-md-1-2::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 200%;
  }
  .equal-md-1-2 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-md-1-1 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-md-1-1::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
  .equal-md-1-1 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 992px) {
  .equal-lg-16-9 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-lg-16-9::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
  }
  .equal-lg-16-9 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-lg-16-10 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-lg-16-10::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 62.5%;
  }
  .equal-lg-16-10 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-lg-4-3 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-lg-4-3::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 75%;
  }
  .equal-lg-4-3 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-lg-3-4 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-lg-3-4::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 133.3333%;
  }
  .equal-lg-3-4 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-lg-2-1 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-lg-2-1::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 50%;
  }
  .equal-lg-2-1 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-lg-1-2 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-lg-1-2::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 200%;
  }
  .equal-lg-1-2 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-lg-1-1 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-lg-1-1::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
  .equal-lg-1-1 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 1200px) {
  .equal-xl-16-9 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xl-16-9::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
  }
  .equal-xl-16-9 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xl-16-10 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xl-16-10::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 62.5%;
  }
  .equal-xl-16-10 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xl-4-3 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xl-4-3::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 75%;
  }
  .equal-xl-4-3 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xl-3-4 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xl-3-4::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 133.3333%;
  }
  .equal-xl-3-4 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xl-2-1 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xl-2-1::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 50%;
  }
  .equal-xl-2-1 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xl-1-2 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xl-1-2::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 200%;
  }
  .equal-xl-1-2 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xl-1-1 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xl-1-1::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
  .equal-xl-1-1 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 1400px) {
  .equal-xxl-16-9 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xxl-16-9::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
  }
  .equal-xxl-16-9 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xxl-16-10 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xxl-16-10::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 62.5%;
  }
  .equal-xxl-16-10 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xxl-4-3 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xxl-4-3::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 75%;
  }
  .equal-xxl-4-3 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xxl-3-4 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xxl-3-4::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 133.3333%;
  }
  .equal-xxl-3-4 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xxl-2-1 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xxl-2-1::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 50%;
  }
  .equal-xxl-2-1 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xxl-1-2 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xxl-1-2::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 200%;
  }
  .equal-xxl-1-2 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .equal-xxl-1-1 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .equal-xxl-1-1::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
  .equal-xxl-1-1 > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  position: relative;
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  width: auto;
  max-width: 100%;
  margin: 0 auto;
}

.foreground {
  position: relative;
  z-index: 12;
}

.back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  width: auto;
}
@media (max-width: 991.98px) {
  .back::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 4;
  }
}
@media (max-width: 991.98px) {
  .back.back-static {
    position: static;
    height: 50vh;
  }
  .back.back-static::after {
    display: none;
  }
}

.sticky {
  z-index: 12;
}

.bg-color {
  transition: all 0.8s;
  transition-delay: 0s;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.bg-color-active .bg-color {
  opacity: 1;
}

.bottom-overlap {
  margin-bottom: -7.5rem;
}

.showcase {
  position: relative;
  transform-origin: bottom left;
}
.showcase > .row {
  position: absolute;
  opacity: 0.5;
}

.level-1 {
  position: relative;
  z-index: 32 !important;
}

.level-2 {
  position: relative;
  z-index: 16 !important;
}

.level-3 {
  position: relative;
  z-index: 8 !important;
}

.split {
  background: #eeeeee;
}

@media (min-width: 992px) {
  .split-sidebar {
    position: relative;
    padding-top: 12.5rem;
  }
  .split-sidebar::after {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    content: "";
    display: block;
    width: 1px;
    background: #dddddd;
  }
}

.split-content {
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 40px;
}
@media (min-width: 992px) {
  .split-content {
    padding-top: 12.5rem;
  }
}
.split-content section:not(:first-child) {
  margin-top: 4rem;
}

.separated > * {
  margin-top: -1px;
  margin-left: -1px;
  border: 1px solid #dddddd;
}

p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child {
  margin-bottom: 0;
}

blockquote {
  margin-bottom: 0;
}

a {
  transition: all 0.2s;
  transition-delay: 0s;
}

code {
  padding: 0.25rem 0.5rem;
  background: #dddddd;
  color: #000000;
  font-weight: 600;
}

.eyebrow {
  display: inline-block;
  font-size: 0.75rem;
  font-family: "Ubuntu", "serif";
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.breadcrumb-item a, .underline {
  display: inline-block;
  position: relative;
}
.breadcrumb-item a:focus, .underline:focus {
  outline: none;
  box-shadow: none;
}
.breadcrumb-item a::before, .underline::before {
  transition: width 0.4s;
  transition-delay: 0s;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  border-bottom: 1px solid currentColor;
}
.breadcrumb-item a:hover, .underline:hover {
  text-decoration: none;
}
.breadcrumb-item a:hover::before, .underline:hover::before {
  width: 100%;
}

.action {
  transition: all 0.2s;
  transition-delay: 0s;
  display: inline-block;
  position: relative;
  padding-right: 2em;
  cursor: pointer;
}
.action .bi {
  transition: all 0.2s;
  transition-delay: 0s;
  transform: translateY(-50%);
  position: absolute;
  right: 0.5em;
  top: 50%;
  display: block;
}
.action:hover .bi {
  right: 0;
}

.list-inline-separated .list-inline-item {
  position: relative;
}
.list-inline-separated .list-inline-item:not(:last-child) {
  margin: 0;
}
.list-inline-separated .list-inline-item:not(:last-child)::after {
  content: "/";
  margin: 0 0.5rem;
}

.text-shadow {
  text-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
}

hr.or {
  position: relative;
  background-color: #dddddd;
}
hr.or::before {
  transform: translate(-50%, -50%);
  position: absolute;
  content: "or";
  left: 50%;
  top: 50%;
  background: #ffffff;
  padding: 0 1rem;
}

.shine {
  overflow: hidden;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 2s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}
.inverted {
  color: #ffffff !important;
}
.inverted [class*=border], .inverted[class*=border] {
  border-color: rgba(255, 255, 255, 0.2) !important;
}
.inverted .text-muted {
  color: rgba(255, 255, 255, 0.5) !important;
}
.inverted .text-secondary {
  color: rgba(255, 255, 255, 0.75) !important;
}
.inverted .highlighted::before {
  background: rgba(255, 255, 255, 0.1);
}
.inverted a:not(.btn):not(.dropdown-item) {
  color: #ffffff;
}
.inverted a:not(.btn):not(.dropdown-item):hover {
  color: rgba(255, 255, 255, 0.75);
}
.inverted .btn-outline-white {
  border-color: rgba(255, 255, 255, 0.2);
}
.inverted .list-group .list-group-item {
  border-color: rgba(255, 255, 255, 0.2);
}
.inverted .list-group-link:hover {
  background: rgba(255, 255, 255, 0.05);
}
.inverted .accordion .accordion-item:not(:first-child) {
  border-color: rgba(255, 255, 255, 0.2);
}
.inverted .browser > span {
  background: #ffffff;
}
.inverted .browser > span::before {
  background: rgba(255, 255, 255, 0.75);
}
.inverted .browser > span::after {
  background: rgba(255, 255, 255, 0.5);
}
.inverted pre[class*=language-],
.inverted code[class*=language-] {
  color: #ffcb2c;
}
.inverted .token {
  color: rgba(255, 255, 255, 0.5);
}
.inverted .token.selector, .inverted .token.punctuation {
  color: #ffffff;
}
.inverted .grouped-inputs.focused {
  border-color: white !important;
}
.inverted .grouped-inputs .form-control,
.inverted .grouped-inputs .form-select {
  color: #ffffff;
}
.inverted .grouped-inputs .form-control::placeholder,
.inverted .grouped-inputs .form-select::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.inverted .progress {
  background-color: rgba(0, 0, 0, 0.2);
}
.inverted .progress .progress-bar {
  background: #ffffff;
  color: rgb(248, 187, 0);
  font-weight: 600;
}
.inverted .form-floating .form-control,
.inverted .form-floating .form-select {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: #ffffff;
}
.inverted .form-floating .form-control:focus,
.inverted .form-floating .form-select:focus {
  background-color: rgba(255, 255, 255, 0.15);
}

.alert {
  overflow: hidden;
  border-width: 0 0 0 0.125rem;
}
.alert > .bi:not(.alert-close) {
  position: absolute;
  transform: rotate(10deg);
  width: 1em;
  height: 1em;
  top: -0.25em;
  left: -0.25em;
  font-size: 12.5rem;
  opacity: 0.1;
}

.alert-close {
  position: absolute;
  top: 50%;
  right: 2rem;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  font-size: 1.5rem;
  cursor: pointer;
}

.navbar {
  padding: 2rem 0;
}

.navbar-sticky {
  transform: translateY(0%);
  transition: all 0.4s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  opacity: 1;
  z-index: 1040;
}

.headroom--unpinned .navbar-sticky,
.offcanvas-push .navbar-sticky {
  transform: translateY(-100%);
  opacity: 0;
}

.headroom--not-top .navbar-sticky {
  padding: 1rem 0;
}
.headroom--not-top .navbar-sticky.navbar-dark {
  background: #000000;
}
.headroom--not-top .navbar-sticky.navbar-light {
  background: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
  text-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
}

.navbar-active .navbar-dark {
  background: #000000;
}
.navbar-active .navbar-light {
  background: #ffffff;
}

.navbar-nav {
  margin-top: 1rem;
}
@media (min-width: 992px) {
  .navbar-nav {
    margin-top: 0;
  }
}
.navbar-nav .nav-link:focus {
  outline: none;
  box-shadow: none;
}
@media (min-width: 992px) {
  .navbar-nav .nav-link {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.navbar-nav .nav-icon {
  display: flex;
  width: 3rem;
  height: 3rem;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}
.navbar-nav .nav-icon:focus {
  outline: none;
  box-shadow: none;
}
.navbar-nav .nav-icon .bi-list {
  font-size: 1.375rem;
  margin-top: 2px;
}

.navbar-nav-secondary {
  margin-top: 0;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 991.98px) {
  .navbar-nav-secondary .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.account-collapse {
  flex-basis: 100%;
  flex-grow: 1;
}

.navbar-brand img {
  width: auto;
  max-height: 2.5rem;
}
@media (max-width: 575.98px) {
  .navbar-brand {
    margin: 0;
  }
}

.navbar-dark .btn-outline-white {
  border-color: rgba(255, 255, 255, 0.25);
}

.navbar-light .navbar-menu .nav-link {
  color: #000000;
  border-color: #dddddd;
}
.navbar-light .navbar-menu .nav-link:hover, .navbar-light .navbar-menu .nav-link[aria-expanded=true] {
  background: #dddddd;
  border-color: transparent;
}

.navbar-active {
  overflow: hidden;
}
.navbar-active .navbar {
  max-height: 100vh;
  overflow-y: scroll;
}
.navbar-active.headroom--unpinned .navbar-sticky {
  padding: 2rem 0;
  transform: translateY(0);
  opacity: 1;
}

.badge {
  vertical-align: middle;
}

.dropdown:focus {
  outline: none;
  box-shadow: none;
}
.dropdown > a:focus {
  outline: none;
  box-shadow: none;
}

.dropdown-menu {
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
  padding: 1.5rem 1rem;
  border: 0;
}
.dropdown-menu .dropdown-item {
  background: transparent;
  position: relative;
  padding: 0.25rem 1rem;
  font-size: 1.05rem;
  color: #555555;
}
.dropdown-menu .dropdown-item:focus {
  outline: none;
  box-shadow: none;
}
.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:active {
  padding-left: 0.75rem;
  padding-right: 1.25rem;
  background: transparent;
  color: #000000;
}
.dropdown-menu .dropdown-item.active {
  color: rgb(248, 187, 0);
  background: transparent;
}
.dropdown-menu .dropdown-item.active:hover, .dropdown-menu .dropdown-item.active:active {
  padding-left: 1rem;
  padding-right: 1rem;
}
.dropdown-menu .dropdown-item span {
  display: block;
}
.dropdown-menu .dropdown-label {
  display: inline-block;
  background: transparent;
  position: relative;
  padding: 0.25rem 1rem;
  font-size: 1.05rem;
}
.dropdown-menu .dropdown-label:focus {
  outline: none;
  box-shadow: none;
}
.dropdown-menu .dropdown-label:not(:first-child) {
  margin-top: 1rem;
}

.dropdown-menu-detailed li + li {
  margin-top: 0.5rem;
}
.dropdown-menu-detailed li span {
  position: relative;
  padding-left: 2.5rem;
}
.dropdown-menu-detailed li span .bi {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -0.5em;
  font-size: 1.5rem;
  color: #000000;
}
.dropdown-menu-detailed li span small, .dropdown-menu-detailed li span .small {
  display: block;
  font-size: 1.05rem;
  color: #777777;
}

@media (min-width: 992px) {
  .dropdown .dropdown-menu {
    transform: translate(-50%, 0);
    left: 50%;
  }
  .dropdown .dropdown-menu[class*=-md] {
    min-width: 25vw;
    column-count: 2;
  }
  .dropdown .dropdown-menu[class*=-custom] {
    min-width: 30vw;
    padding: 0;
  }

  .dropend .dropdown-menu {
    transform: translate(0, 0);
    left: 100%;
    top: -1.5rem;
  }
  .dropend .dropdown-toggle::after {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    margin-top: -2px;
    border-width: 0.25rem 0 0.25rem 0.25rem;
    border-style: solid;
    color: #ced4da;
  }

  .dropdown-hover > .dropdown-menu {
    transition: all 0.2s ease-in-out;
    display: block;
    pointer-events: none;
    opacity: 0;
    margin: 0.625rem 0 0 0;
  }
  .dropdown-hover:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
    margin: 0;
  }
  .dropdown-hover > .dropdown-toggle::after {
    display: none;
  }

  .dropend-hover:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
    margin: 0;
  }
  .dropend-hover > .dropdown-menu {
    transition: all 0.2s;
    transition-delay: 0s;
    display: block;
    pointer-events: none;
    margin: 0 0 0 0.625rem;
    opacity: 0;
  }
}
.form-switch .form-check-input {
  border-radius: 6.25rem;
}

.form-switch-right {
  position: relative;
  width: 100%;
  margin: 0;
  padding-left: 0;
  padding-right: 3.5rem;
}
.form-switch-right:hover .form-check-label {
  opacity: 0.75;
}
.form-switch-right .form-check-label {
  transition: all 0.2s;
  transition-delay: 0s;
  width: 100%;
  opacity: 0.5;
  cursor: pointer;
}
.form-switch-right .form-check-input {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.form-switch-right .form-check-input:checked ~ .form-check-label {
  opacity: 1;
}

.form-check-minimal {
  padding: 0;
}
.form-check-minimal .form-check-input {
  display: none;
}
.form-check-minimal .form-check-input:checked ~ .form-check-label {
  padding-left: 0.5rem;
  padding-right: 2.5rem;
  border-color: #dddddd;
}
.form-check-minimal .form-check-input:checked ~ .form-check-label::before {
  opacity: 1;
}
.form-check-minimal .form-check-label {
  display: inline-flex;
  position: relative;
  transition: all 0.2s;
  cursor: pointer;
  padding: 0.25rem 0.5rem 0.25rem 0;
  line-height: 1.5rem;
  border: 1px solid transparent;
}
.form-check-minimal .form-check-label::before {
  transition: all 0.2s;
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-family: bootstrap-icons !important;
  font-size: 1.2rem;
  text-align: center;
  content: "\f62a";
  background: #dddddd;
  opacity: 0;
}
.form-check-minimal .form-check-label:hover {
  color: rgb(248, 187, 0);
}

.form-check-color {
  padding: 0;
}
.form-check-color .form-check-input {
  display: none;
}
.form-check-color .form-check-input:checked ~ .form-check-label {
  padding-left: 0.5rem;
  padding-right: 2.5rem;
  border-color: #dddddd;
}
.form-check-color .form-check-input:checked ~ .form-check-label::before {
  opacity: 1;
}
.form-check-color .form-check-label {
  display: inline-flex;
  align-items: center;
  position: relative;
  transition: all 0.2s;
  cursor: pointer;
  padding: 0.25rem 0.5rem 0.25rem 0;
  line-height: 1.5rem;
  border: 1px solid transparent;
}
.form-check-color .form-check-label::before {
  transition: all 0.2s;
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-family: bootstrap-icons !important;
  font-size: 1.2rem;
  text-align: center;
  content: "\f62a";
  background: #dddddd;
  opacity: 0;
}
.form-check-color .form-check-label:hover {
  color: rgb(248, 187, 0);
}
.form-check-color .form-check-label span {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.grouped-inputs {
  transition: all 0.3s ease-in-out;
  border-radius: 2.5rem !important;
}
.grouped-inputs.focused {
  border-color: var(--bs-primary) !important;
}
.grouped-inputs .form-control,
.grouped-inputs .form-select {
  border-color: transparent;
  background-color: transparent;
}
.grouped-inputs .form-control:focus,
.grouped-inputs .form-select:focus {
  outline: none;
  box-shadow: none;
}
.grouped-inputs .form-select {
  background-position: right 1.75rem center;
}

.plyr {
  --plyr-color-main: var(--primary);
}
.plyr .plyr__controls {
  padding: 1.5rem;
}
.plyr .plyr__control--overlaid {
  width: 6.25rem;
  height: 6.25rem;
  padding: 0;
  background: #000000;
}
.plyr .plyr__control--overlaid:hover {
  background: rgba(0, 0, 0, 0.75);
}
.plyr .plyr__control--overlaid svg {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.plyr.plyr--stopped .plyr__controls {
  opacity: 0;
}
.plyr .plyr__poster {
  transition: all 0.2s;
  transition-delay: 0s;
  background-size: cover;
  opacity: 0;
  cursor: pointer;
}

.plyr__video-embed {
  position: relative;
}
.plyr__video-embed iframe {
  width: 100%;
  height: 100%;
}

.nav .nav-link {
  position: relative;
  color: inherit;
}

.nav-tabs {
  position: relative;
  border: 0;
}
.nav-tabs .nav-item {
  margin: 0 1rem 0.5rem 0;
}
.nav-tabs .nav-link {
  transition: all 0.3s ease-in-out;
  display: inline-block;
  padding: 0.5rem 0;
  border-width: 0 0 1px 0;
  border-radius: 0;
  border-color: transparent;
  text-align: left;
  background: transparent;
  color: #999999;
}
.nav-tabs .nav-link:hover {
  color: #000000;
  border-color: transparent;
}
.nav-tabs .nav-link.active {
  color: #000000;
  border-color: #000000;
  background: transparent;
}

.nav-item .nav-label {
  display: flex;
  color: #000000;
}
.nav-item:not(:first-child) .nav-label {
  margin-top: 0.75rem;
}

.nav-pills {
  display: inline-flex;
  border: 1px solid #dddddd;
}
.nav-pills .nav-link {
  padding: 0.75rem 1.5rem;
  color: #555555;
  background: transparent;
}
.nav-pills .nav-link.active {
  color: #000000;
  background: #dddddd;
}

.nav-minimal {
  display: flex;
  flex-direction: column;
}
.nav-minimal .nav-item:not(:first-child) {
  margin-top: 1rem;
}
.nav-minimal .nav-link {
  display: inline-block;
  position: relative;
  padding: 0 2rem 0 0;
}
.nav-minimal .nav-link[aria-expanded]::before {
  transition: transform 0.2s;
  position: absolute;
  top: 50%;
  right: 0;
  content: "\f282";
  width: 1.5rem;
  margin-top: -0.75rem;
  text-align: center;
  line-height: 1.5rem;
  font-family: bootstrap-icons !important;
  font-size: 1.125rem;
  color: #999999;
}
.nav-minimal .nav-link[aria-expanded=true]::before {
  transform: rotate(180deg);
}
.nav-minimal .nav-link[class*=active] {
  color: rgb(248, 187, 0) !important;
}
.nav-minimal .nav-link:hover {
  color: rgb(248, 187, 0) !important;
}
.nav-minimal .nav-minimal {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.nav-minimal .nav-minimal .nav-item {
  margin-top: 0.5rem;
}
.nav-minimal .nav-minimal .nav-link {
  color: #555555;
}

.carousel:hover .tns-controls button {
  opacity: 1;
}
.carousel:hover .tns-controls button[data-controls=prev] {
  left: 1rem;
}
.carousel:hover .tns-controls button[data-controls=next] {
  right: 1rem;
}
@media (max-width: 991.98px) {
  .carousel .tns-controls button {
    opacity: 1;
  }
}
.carousel[class*=-visible] .tns-ovh {
  overflow: visible !important;
}
.carousel[class*=-with-nav] {
  margin-bottom: 3.5rem;
}
.carousel[class*=-with-nav] .tns-nav {
  position: absolute;
  bottom: -3.5rem;
  width: 100%;
}
.carousel[class*=-big-controls] .tns-controls {
  position: absolute;
  right: 0;
  bottom: 0;
}
.carousel[class*=-big-controls] .tns-controls button {
  position: relative;
  width: 5rem;
  height: 5rem;
  background: #000000;
  font-size: 1.5rem;
  margin-top: -2.5rem;
  border-radius: 0;
  opacity: 1;
}
.carousel[class*=-big-controls] .tns-controls button[data-controls=prev] {
  left: 0;
}
.carousel[class*=-big-controls] .tns-controls button[data-controls=next] {
  right: 0;
}
.carousel[class*=-big-controls] .tns-controls button:hover {
  background: rgb(248, 187, 0);
}
.carousel[class*=-big-controls] .tns-controls button:active {
  background: #c59500;
}
.carousel[class*=-align] [data-carousel] {
  display: flex;
  align-items: center;
}

.carousel-steps {
  cursor: grab;
}
.carousel-steps .tns-item {
  user-select: none;
  position: relative;
  padding-top: 6rem;
  counter-increment: slider;
}
.carousel-steps .tns-item:focus {
  outline: none;
  box-shadow: none;
}
.carousel-steps .tns-item::before {
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  content: counter(slider, decimal);
  display: flex;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  font-size: 1.35rem;
  background: #000000;
  color: #ffffff;
  border-radius: 50%;
  z-index: 4;
}
.carousel-steps .tns-item:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 1.875rem;
  left: 4.5rem;
  right: 0.5rem;
  height: 0.125rem;
  display: flex;
  background: #dddddd;
}
.carousel-steps .tns-item.tns-slide-active::before {
  background: var(--bs-primary);
}

.carousel-thumbs > div {
  opacity: 0.25;
  max-width: 100%;
  cursor: pointer;
}
.carousel-thumbs > div:focus {
  outline: none;
  box-shadow: none;
}
.carousel-thumbs > div.tns-nav-active {
  opacity: 1;
}

.tns-thumbs {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.tns-thumbs [data-nav]:focus {
  outline: none;
  box-shadow: none;
}
.tns-thumbs [data-nav] .tns-thumbs-item {
  opacity: 0.5;
}
.tns-thumbs [data-nav].tns-nav-active .tns-thumbs-item {
  opacity: 1;
}
.tns-thumbs [data-nav].tns-nav-active .tns-thumbs-item::before {
  background-color: #ffffff;
}
.tns-thumbs .tns-thumbs-item {
  position: relative;
  display: block;
  position: relative;
  cursor: pointer;
}
.tns-thumbs .tns-thumbs-item::before {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  display: flex;
  content: "";
  height: 1px;
}

.tns-item img {
  max-width: 100%;
}

.tns-controls {
  display: flex;
  justify-content: space-between;
}
.tns-controls:focus {
  outline: none;
  box-shadow: none;
}
.tns-controls button {
  transition: all 0.2s;
  border: 0;
  display: flex;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  text-align: center;
  font-size: 1.25rem;
  color: #000000;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  position: absolute;
  top: 50%;
  z-index: 4;
  margin-top: -1.5rem;
}
.tns-controls button .bi {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 1em;
  margin: -0.5em 0 0 -0.5em;
  color: #ffffff;
}
.tns-controls button:hover {
  background: #000000;
}
.tns-controls button[disabled] {
  opacity: 0.2;
}
.tns-controls button[data-controls=prev] {
  left: 1.5rem;
}
.tns-controls button[data-controls=next] {
  right: 1.5rem;
}

.tns-nav {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  padding: 0;
  list-style: none;
}
.tns-nav:focus {
  outline: none;
  box-shadow: none;
}
.tns-nav button {
  position: relative;
  cursor: pointer;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  border: 0;
  border-radius: 50%;
  background: transparent;
}
.tns-nav button:focus {
  outline: none;
  box-shadow: none;
}
.tns-nav button::before {
  transition: all 0.2s;
  transition-delay: 0s;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  margin-top: -0.25rem;
  margin-left: -0.25rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.tns-nav button.tns-nav-active::before, .tns-nav button.tns-nav-active:hover::before {
  background: #000000;
}
.tns-nav button:hover::before {
  background: rgba(0, 0, 0, 0.4);
}

figure {
  margin: 0;
  background-size: cover;
  background-position: center;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.background .plyr {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 135%;
  max-width: 135%;
}
.background .plyr > .plyr__control {
  display: none !important;
}
.background .map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (max-width: 991.98px) {
  .background .plyr {
    width: 240%;
    max-width: 240%;
  }
}
@media (max-width: 767.98px) {
  .background .plyr {
    width: 385%;
    max-width: 385%;
  }
}
.background[class*=-overlay]::after {
  transition: opacity 0.2s;
  position: absolute;
  opacity: 0.4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: #000000;
}
.background[class*=-dimm] {
  opacity: 0.3;
}
.background[class*=-gradient-horizontal] {
  mask-image: -webkit-gradient(linear, right bottom, left bottom, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0)));
}
.background[class*=-gradient-vertical] {
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 25%, rgb(0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);
}
.background[class*=-gradient-vertical]::before {
  transition: all 0.2s;
  transition-delay: 0s;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 24;
  background-color: rgba(0, 0, 0, 0.4);
}

.hover-gradient {
  transition: all 0.2s;
  transition-delay: 0s;
  background: #000000;
}
.hover-gradient .background {
  mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0.4)));
}

.media {
  width: 100%;
  margin: 0;
  overflow: hidden;
}
.media span,
.media video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.media[class*=-background] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.media[class*=-background] > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.media[class*=-video] span {
  transition: transform 0.3s ease-in-out;
  transform: scale(1);
}
.media[class*=-video]::after {
  transform: scale(1.1);
  transition: all 0.2s;
  position: absolute;
  font-family: bootstrap-icons;
  content: "\f4f4";
  top: 50%;
  left: 50%;
  width: 6.25rem;
  height: 6.25rem;
  margin-top: -3.125rem;
  margin-left: -3.125rem;
  line-height: 6.25rem;
  border-radius: 6.25rem;
  background-color: #000000;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  font-size: 2rem;
  cursor: pointer;
}
.media[class*=-video]:hover::after {
  transform: scale(1);
}
.media[class*=-video]:hover span {
  transform: scale(1.05);
}
.media[class*=-image] span {
  transition: transform 0.3s ease-in-out;
  transform: scale(1);
}
.media[class*=-image]:hover span {
  transform: scale(1.05);
}

.background-parallax {
  width: auto;
  height: 7.5%rem;
  margin-top: -20%;
}

.frame {
  position: relative;
  padding: 1rem;
}
.frame > * {
  transition: transform 0.2s;
  transform: translate(-1rem, -1rem);
  position: relative;
  z-index: 4;
}
.frame > span {
  transition: transform 0.2s;
  transform: translate(1rem, 1rem);
  position: absolute;
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
  display: block;
  border: 1px solid #dddddd;
  z-index: 0;
}
.frame[data-aos] > *,
.frame[data-aos] > span {
  transform: translate(0, 0);
}
.frame[data-aos].aos-animate > * {
  transform: translate(-1rem, -1rem);
}
.frame[data-aos].aos-animate > span {
  transform: translate(1rem, 1rem);
}

.img-stack {
  position: relative;
}
.img-stack img {
  position: relative;
  z-index: 8;
  margin-top: -0.625rem;
}
.img-stack img + img {
  position: absolute;
  z-index: 4;
}

.browser {
  position: relative;
  background: #ffffff;
  padding-top: 3.5rem;
  overflow: hidden;
}
.browser > span {
  display: flex;
  position: absolute;
  top: 1.75rem;
  margin-top: -0.375rem;
  left: 2rem;
  width: 0.75rem;
  height: 0.75rem;
  background: #d34f2d;
  border-radius: 50%;
}
.browser > span::before, .browser > span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 1.5rem;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  background: rgb(248, 187, 0);
  border-radius: 50%;
}
.browser > span::after {
  left: 3rem;
  background: #1d4b40;
}
.browser img {
  vertical-align: top;
}

.list-group-flush .list-group-item {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.list-group-minimal .list-group-item {
  border: 0;
  padding-left: 0;
  padding-right: 0;
}

.list-group-separated .list-group-item {
  border: 0;
  padding-left: 0;
  padding-right: 0;
}
.list-group-separated .list-group-item:not(:first-child) {
  border-top: 1px solid #dddddd;
}

.list-group-numbered .list-group-item {
  padding-left: 2rem;
}
.list-group-numbered .list-group-item::before {
  position: absolute;
  left: 0.5rem;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1em;
  font-size: 1.35rem;
}
.list-group-numbered.list-group-separated .list-group-item::before, .list-group-numbered.list-group-minimal .list-group-item::before {
  left: 0;
}

.card {
  transition: all 0.2s;
  transition-delay: 0s;
  color: inherit;
}
.card > .card-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 4;
}
.card .equal,
.card .background {
  z-index: 0;
}
.card.bg-opaque-white {
  background: rgba(255, 255, 255, 0.4) !important;
}
.card [class*=language-] {
  max-height: 25vh;
}

.card-title {
  font-size: calc(1.275rem + 0.3vw);
  position: relative;
  display: block;
  color: inherit;
}
@media (min-width: 1200px) {
  .card-title {
    font-size: 1.5rem;
  }
}
.card-title > * {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  font-size: inherit;
}

.card:hover {
  z-index: 4;
}

.card-arrow .card-header,
.card-arrow .card-footer,
.card-arrow .card-body {
  padding-right: 4rem;
}
.card-arrow.equal {
  padding-right: 0;
}
.card-arrow.equal .card-wrap {
  padding-right: 3.5rem;
}
.card-arrow::after {
  transition: all 0.2s ease-in-out;
  position: absolute;
  right: 2.5rem;
  bottom: 2.5rem;
  display: inline-block;
  vertical-align: middle;
  font-family: bootstrap-icons;
  font-size: 1.5rem;
  line-height: 1em;
  z-index: 4;
  color: inherit;
  content: "\f123";
}
.card-arrow:not(.card-hover-arrow):hover::after {
  right: 2rem;
  bottom: 2rem;
}

.card-hover-reveal {
  overflow: hidden;
}
.card-hover-reveal > * {
  z-index: 4;
}
.card-hover-reveal .background {
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: scale(1.1);
  z-index: 2;
}
.card-hover-reveal:hover {
  color: #ffffff;
}
.card-hover-reveal:hover .background {
  opacity: 1;
  transform: scale(1);
}

.card-hover-arrow::after {
  opacity: 0;
  transform: translateY(50%);
}
.card-hover-arrow:hover::after {
  opacity: 1;
  transform: translateY(0);
}

.card-hover-gradient {
  transition: background-color 0.2s ease-in-out;
  background-color: #000000;
}
.card-hover-gradient .background {
  mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0.4)));
}
.card-hover-gradient:hover {
  background-color: rgb(248, 187, 0);
}

.card-hover-rise:hover {
  transform: translateY(-8px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.card-hover-image-rise:hover .card-img-top {
  transform: translateY(-8px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.card-hover-border:hover,
.card-hover-border:active {
  box-shadow: 0 0 0 1px currentColor;
}

.card-img-top {
  transition: all 0.2s;
  transition-delay: 0s;
}

.card .accordion-classic {
  border: 0;
}
.card .accordion-classic .accordion-item .accordion-button {
  padding: 2rem 3.5rem 2rem 2rem;
}
.card .accordion-classic .accordion-item .accordion-button::after {
  right: 1.5rem;
}
.card .accordion-classic .accordion-item .accordion-body {
  padding: 0 3.5rem 2rem 2rem;
}

.accordion .accordion-item {
  position: relative;
  border: 0;
  background: transparent;
}
.accordion .accordion-item:not(:first-child) {
  border-top: 1px solid #dddddd;
}
.accordion .accordion-button {
  transition: all 0.2s;
  position: relative;
  background-color: transparent;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2.5rem;
  padding-left: 0;
  box-shadow: none;
  color: inherit;
}
.accordion .accordion-button:not(.collapsed) {
  color: inherit;
}
.accordion .accordion-button .row {
  flex: 1 1 auto;
}
.accordion .accordion-button::after {
  position: absolute;
  top: 50%;
  right: 0;
  font-family: bootstrap-icons !important;
  font-size: 1.2rem;
  content: "\f282";
  color: inherit;
  margin-top: -0.5em;
  width: 1em;
  height: 1em;
  background: transparent;
}
.accordion .accordion-body {
  padding: 0 2.5rem 2rem 0;
}

.accordion-classic {
  border: 1px solid #dddddd;
  overflow: hidden;
}
.accordion-classic .accordion-button {
  padding: 2rem;
}
.accordion-classic .accordion-button::after {
  right: 2rem;
}
.accordion-classic .accordion-body {
  padding: 0 2rem 2rem 2rem;
}

.accordion-minimal .accordion-item:not(:first-child) {
  border-top: 0;
  margin-top: 1.5rem;
}
.accordion-minimal .accordion-button,
.accordion-minimal .accordion-body {
  padding: 0 0 0 2.5rem;
}
.accordion-minimal .accordion-body {
  padding-top: 1rem;
}
.accordion-minimal .accordion-button::after {
  right: auto;
  left: 0;
  content: "\f64d";
}
.accordion-minimal .accordion-button:not(.collapsed)::after {
  transform: rotate(-135deg);
}

.accordion-steps .accordion-item {
  position: relative;
  counter-increment: accordion;
}
.accordion-steps .accordion-item:not(:first-child) {
  border-top: 0;
  margin-top: 2.5rem;
}
.accordion-steps .accordion-item:not(:first-child)::before {
  content: "";
  position: absolute;
  width: 0.125rem;
  top: -2rem;
  left: 1.875rem;
  bottom: calc(50% + 40px);
  background: #dddddd;
}
.accordion-steps .accordion-item:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 0.125rem;
  top: calc(50% + 40px);
  left: 1.875rem;
  bottom: -2rem;
  background: #dddddd;
}
.accordion-steps .accordion-button {
  position: static;
  min-height: 4rem;
}
.accordion-steps .accordion-button::before {
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 0;
  content: counter(accordion, decimal);
  display: flex;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  margin-top: -2rem;
  background: #000000;
  color: #ffffff;
  font-size: 1.35rem;
  border-radius: 50%;
  z-index: 4;
}
.accordion-steps .accordion-button::after {
  position: static;
  margin-left: 0.5rem;
  margin-top: 0;
}
.accordion-steps .accordion-button[aria-expanded=true] {
  color: var(--bs-primary);
}
.accordion-steps .accordion-button[aria-expanded=true]::before {
  background: var(--bs-primary);
}
.accordion-steps .accordion-button:hover {
  color: var(--bs-primary);
}
.accordion-steps .accordion-button:hover::before {
  background: var(--bs-primary);
}
.accordion-steps .accordion-button,
.accordion-steps .accordion-body {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 6rem;
}
.accordion-steps .accordion-body {
  padding-bottom: 1rem;
}

.accordion[class*=-highlight]:hover .accordion-button {
  opacity: 0.5;
}
.accordion[class*=-highlight] .accordion-button:hover, .accordion[class*=-highlight] .accordion-button:not(.collapsed) {
  opacity: 1;
}

.accordion[class*=-select] .accordion-item::before {
  transition: all 0.2s;
  transition-delay: 0s;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.04);
  opacity: 0;
}
.accordion[class*=-select] .accordion-item:hover::before {
  opacity: 1;
  left: -1rem;
  right: -1rem;
}

.rating {
  display: inline-flex;
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.rating li:not(:first-child) {
  margin-left: 0.4em;
}
.rating i {
  color: inherit;
  font-size: inherit;
}

.blockquote-footer {
  margin: 0;
}
.blockquote-footer::before {
  display: none;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
}
.avatar span {
  font-size: 1.2rem;
}
.avatar[class*=-sm] {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar[class*=-sm] span {
  font-size: 1.05rem;
}
.avatar[class*=-lg] {
  width: 4.5rem;
  height: 4.5rem;
}
.avatar[class*=-lg] span {
  font-size: 1.25rem;
}
.avatar img {
  max-width: 100%;
}
.avatar span {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.avatar-list {
  display: inline-flex;
  padding: 0;
  list-style: none;
}
.avatar-list li {
  position: relative;
  z-index: 2;
}
.avatar-list li:hover {
  z-index: 4;
}
.avatar-list li:not(:first-child) {
  margin-left: -1rem;
}

.btn-blue {
  color: #ffffff;
  background-color: #4726e1;
  border-color: #4726e1;
}
.btn-blue:hover {
  color: #ffffff;
  background-color: #3c20bf;
  border-color: #391eb4;
}
.btn-check:focus + .btn-blue, .btn-blue:focus {
  color: #ffffff;
  background-color: #3c20bf;
  border-color: #391eb4;
  box-shadow: 0 0 0 0 rgba(99, 71, 230, 0.5);
}
.btn-check:checked + .btn-blue, .btn-check:active + .btn-blue, .btn-blue:active, .btn-blue.active, .show > .btn-blue.dropdown-toggle {
  color: #ffffff;
  background-color: #391eb4;
  border-color: #351da9;
}
.btn-check:checked + .btn-blue:focus, .btn-check:active + .btn-blue:focus, .btn-blue:active:focus, .btn-blue.active:focus, .show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(99, 71, 230, 0.5);
}
.btn-blue:disabled, .btn-blue.disabled {
  color: #ffffff;
  background-color: #4726e1;
  border-color: #4726e1;
}

.btn-indigo {
  color: #ffffff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-indigo:hover {
  color: #ffffff;
  background-color: #570ece;
  border-color: #520dc2;
}
.btn-check:focus + .btn-indigo, .btn-indigo:focus {
  color: #ffffff;
  background-color: #570ece;
  border-color: #520dc2;
  box-shadow: 0 0 0 0 rgba(125, 52, 244, 0.5);
}
.btn-check:checked + .btn-indigo, .btn-check:active + .btn-indigo, .btn-indigo:active, .btn-indigo.active, .show > .btn-indigo.dropdown-toggle {
  color: #ffffff;
  background-color: #520dc2;
  border-color: #4d0cb6;
}
.btn-check:checked + .btn-indigo:focus, .btn-check:active + .btn-indigo:focus, .btn-indigo:active:focus, .btn-indigo.active:focus, .show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(125, 52, 244, 0.5);
}
.btn-indigo:disabled, .btn-indigo.disabled {
  color: #ffffff;
  background-color: #6610f2;
  border-color: #6610f2;
}

.btn-purple {
  color: #ffffff;
  background-color: #300d81;
  border-color: #300d81;
}
.btn-purple:hover {
  color: #ffffff;
  background-color: #290b6e;
  border-color: #260a67;
}
.btn-check:focus + .btn-purple, .btn-purple:focus {
  color: #ffffff;
  background-color: #290b6e;
  border-color: #260a67;
  box-shadow: 0 0 0 0 rgba(79, 49, 148, 0.5);
}
.btn-check:checked + .btn-purple, .btn-check:active + .btn-purple, .btn-purple:active, .btn-purple.active, .show > .btn-purple.dropdown-toggle {
  color: #ffffff;
  background-color: #260a67;
  border-color: #240a61;
}
.btn-check:checked + .btn-purple:focus, .btn-check:active + .btn-purple:focus, .btn-purple:active:focus, .btn-purple.active:focus, .show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(79, 49, 148, 0.5);
}
.btn-purple:disabled, .btn-purple.disabled {
  color: #ffffff;
  background-color: #300d81;
  border-color: #300d81;
}

.btn-pink {
  color: #ffffff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-pink:hover {
  color: #ffffff;
  background-color: #b62b70;
  border-color: #ab296a;
}
.btn-check:focus + .btn-pink, .btn-pink:focus {
  color: #ffffff;
  background-color: #b62b70;
  border-color: #ab296a;
  box-shadow: 0 0 0 0 rgba(220, 82, 150, 0.5);
}
.btn-check:checked + .btn-pink, .btn-check:active + .btn-pink, .btn-pink:active, .btn-pink.active, .show > .btn-pink.dropdown-toggle {
  color: #ffffff;
  background-color: #ab296a;
  border-color: #a12663;
}
.btn-check:checked + .btn-pink:focus, .btn-check:active + .btn-pink:focus, .btn-pink:active:focus, .btn-pink.active:focus, .show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(220, 82, 150, 0.5);
}
.btn-pink:disabled, .btn-pink.disabled {
  color: #ffffff;
  background-color: #d63384;
  border-color: #d63384;
}

.btn-red {
  color: #000000;
  background-color: #d34f2d;
  border-color: #d34f2d;
}
.btn-red:hover {
  color: #000000;
  background-color: #da694d;
  border-color: #d76142;
}
.btn-check:focus + .btn-red, .btn-red:focus {
  color: #000000;
  background-color: #da694d;
  border-color: #d76142;
  box-shadow: 0 0 0 0 rgba(179, 67, 38, 0.5);
}
.btn-check:checked + .btn-red, .btn-check:active + .btn-red, .btn-red:active, .btn-red.active, .show > .btn-red.dropdown-toggle {
  color: #000000;
  background-color: #dc7257;
  border-color: #d76142;
}
.btn-check:checked + .btn-red:focus, .btn-check:active + .btn-red:focus, .btn-red:active:focus, .btn-red.active:focus, .show > .btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(179, 67, 38, 0.5);
}
.btn-red:disabled, .btn-red.disabled {
  color: #000000;
  background-color: #d34f2d;
  border-color: #d34f2d;
}

.btn-orange {
  color: #000000;
  background-color: #f08d34;
  border-color: #f08d34;
}
.btn-orange:hover {
  color: #000000;
  background-color: #f29e52;
  border-color: #f29848;
}
.btn-check:focus + .btn-orange, .btn-orange:focus {
  color: #000000;
  background-color: #f29e52;
  border-color: #f29848;
  box-shadow: 0 0 0 0 rgba(204, 120, 44, 0.5);
}
.btn-check:checked + .btn-orange, .btn-check:active + .btn-orange, .btn-orange:active, .btn-orange.active, .show > .btn-orange.dropdown-toggle {
  color: #000000;
  background-color: #f3a45d;
  border-color: #f29848;
}
.btn-check:checked + .btn-orange:focus, .btn-check:active + .btn-orange:focus, .btn-orange:active:focus, .btn-orange.active:focus, .show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(204, 120, 44, 0.5);
}
.btn-orange:disabled, .btn-orange.disabled {
  color: #000000;
  background-color: #f08d34;
  border-color: #f08d34;
}

.btn-yellow {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-yellow:hover {
  color: #000000;
  background-color: #f9c526;
  border-color: #f9c21a;
}
.btn-check:focus + .btn-yellow, .btn-yellow:focus {
  color: #000000;
  background-color: #f9c526;
  border-color: #f9c21a;
  box-shadow: 0 0 0 0 rgba(211, 159, 0, 0.5);
}
.btn-check:checked + .btn-yellow, .btn-check:active + .btn-yellow, .btn-yellow:active, .btn-yellow.active, .show > .btn-yellow.dropdown-toggle {
  color: #000000;
  background-color: #f9c933;
  border-color: #f9c21a;
}
.btn-check:checked + .btn-yellow:focus, .btn-check:active + .btn-yellow:focus, .btn-yellow:active:focus, .btn-yellow.active:focus, .show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 159, 0, 0.5);
}
.btn-yellow:disabled, .btn-yellow.disabled {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}

.btn-green {
  color: #ffffff;
  background-color: #1d4b40;
  border-color: #1d4b40;
}
.btn-green:hover {
  color: #ffffff;
  background-color: #194036;
  border-color: #173c33;
}
.btn-check:focus + .btn-green, .btn-green:focus {
  color: #ffffff;
  background-color: #194036;
  border-color: #173c33;
  box-shadow: 0 0 0 0 rgba(63, 102, 93, 0.5);
}
.btn-check:checked + .btn-green, .btn-check:active + .btn-green, .btn-green:active, .btn-green.active, .show > .btn-green.dropdown-toggle {
  color: #ffffff;
  background-color: #173c33;
  border-color: #163830;
}
.btn-check:checked + .btn-green:focus, .btn-check:active + .btn-green:focus, .btn-green:active:focus, .btn-green.active:focus, .show > .btn-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(63, 102, 93, 0.5);
}
.btn-green:disabled, .btn-green.disabled {
  color: #ffffff;
  background-color: #1d4b40;
  border-color: #1d4b40;
}

.btn-teal {
  color: #000000;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-teal:hover {
  color: #000000;
  background-color: #41d1a7;
  border-color: #36cea1;
}
.btn-check:focus + .btn-teal, .btn-teal:focus {
  color: #000000;
  background-color: #41d1a7;
  border-color: #36cea1;
  box-shadow: 0 0 0 0 rgba(27, 171, 128, 0.5);
}
.btn-check:checked + .btn-teal, .btn-check:active + .btn-teal, .btn-teal:active, .btn-teal.active, .show > .btn-teal.dropdown-toggle {
  color: #000000;
  background-color: #4dd4ac;
  border-color: #36cea1;
}
.btn-check:checked + .btn-teal:focus, .btn-check:active + .btn-teal:focus, .btn-teal:active:focus, .btn-teal.active:focus, .show > .btn-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(27, 171, 128, 0.5);
}
.btn-teal:disabled, .btn-teal.disabled {
  color: #000000;
  background-color: #20c997;
  border-color: #20c997;
}

.btn-cyan {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-cyan:hover {
  color: #000000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-cyan, .btn-cyan:focus {
  color: #000000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0 rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-cyan, .btn-check:active + .btn-cyan, .btn-cyan:active, .btn-cyan.active, .show > .btn-cyan.dropdown-toggle {
  color: #000000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-cyan:focus, .btn-check:active + .btn-cyan:focus, .btn-cyan:active:focus, .btn-cyan.active:focus, .show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(11, 172, 204, 0.5);
}
.btn-cyan:disabled, .btn-cyan.disabled {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-white {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-white:hover {
  color: #000000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-white, .btn-white:focus {
  color: #000000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0 rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #000000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(217, 217, 217, 0.5);
}
.btn-white:disabled, .btn-white.disabled {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-gray {
  color: #000000;
  background-color: #999999;
  border-color: #999999;
}
.btn-gray:hover {
  color: #000000;
  background-color: #a8a8a8;
  border-color: #a3a3a3;
}
.btn-check:focus + .btn-gray, .btn-gray:focus {
  color: #000000;
  background-color: #a8a8a8;
  border-color: #a3a3a3;
  box-shadow: 0 0 0 0 rgba(130, 130, 130, 0.5);
}
.btn-check:checked + .btn-gray, .btn-check:active + .btn-gray, .btn-gray:active, .btn-gray.active, .show > .btn-gray.dropdown-toggle {
  color: #000000;
  background-color: #adadad;
  border-color: #a3a3a3;
}
.btn-check:checked + .btn-gray:focus, .btn-check:active + .btn-gray:focus, .btn-gray:active:focus, .btn-gray.active:focus, .show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(130, 130, 130, 0.5);
}
.btn-gray:disabled, .btn-gray.disabled {
  color: #000000;
  background-color: #999999;
  border-color: #999999;
}

.btn-gray-dark {
  color: #ffffff;
  background-color: #555555;
  border-color: #555555;
}
.btn-gray-dark:hover {
  color: #ffffff;
  background-color: #484848;
  border-color: #444444;
}
.btn-check:focus + .btn-gray-dark, .btn-gray-dark:focus {
  color: #ffffff;
  background-color: #484848;
  border-color: #444444;
  box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5);
}
.btn-check:checked + .btn-gray-dark, .btn-check:active + .btn-gray-dark, .btn-gray-dark:active, .btn-gray-dark.active, .show > .btn-gray-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #444444;
  border-color: #404040;
}
.btn-check:checked + .btn-gray-dark:focus, .btn-check:active + .btn-gray-dark:focus, .btn-gray-dark:active:focus, .btn-gray-dark.active:focus, .show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5);
}
.btn-gray-dark:disabled, .btn-gray-dark.disabled {
  color: #ffffff;
  background-color: #555555;
  border-color: #555555;
}

.btn-black {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-black:hover {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-check:focus + .btn-black, .btn-black:focus {
  color: #ffffff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0 rgba(38, 38, 38, 0.5);
}
.btn-check:checked + .btn-black, .btn-check:active + .btn-black, .btn-black:active, .btn-black.active, .show > .btn-black.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-check:checked + .btn-black:focus, .btn-check:active + .btn-black:focus, .btn-black:active:focus, .btn-black.active:focus, .show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 38, 38, 0.5);
}
.btn-black:disabled, .btn-black.disabled {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.btn-gold {
  color: #000000;
  background-color: #807764;
  border-color: #807764;
}
.btn-gold:hover {
  color: #000000;
  background-color: #938b7b;
  border-color: #8d8574;
}
.btn-check:focus + .btn-gold, .btn-gold:focus {
  color: #000000;
  background-color: #938b7b;
  border-color: #8d8574;
  box-shadow: 0 0 0 0 rgba(109, 101, 85, 0.5);
}
.btn-check:checked + .btn-gold, .btn-check:active + .btn-gold, .btn-gold:active, .btn-gold.active, .show > .btn-gold.dropdown-toggle {
  color: #000000;
  background-color: #999283;
  border-color: #8d8574;
}
.btn-check:checked + .btn-gold:focus, .btn-check:active + .btn-gold:focus, .btn-gold:active:focus, .btn-gold.active:focus, .show > .btn-gold.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(109, 101, 85, 0.5);
}
.btn-gold:disabled, .btn-gold.disabled {
  color: #000000;
  background-color: #807764;
  border-color: #807764;
}

.btn-border-color {
  color: #000000;
  background-color: #dddddd;
  border-color: #dddddd;
}
.btn-border-color:hover {
  color: #000000;
  background-color: #e2e2e2;
  border-color: #e0e0e0;
}
.btn-check:focus + .btn-border-color, .btn-border-color:focus {
  color: #000000;
  background-color: #e2e2e2;
  border-color: #e0e0e0;
  box-shadow: 0 0 0 0 rgba(188, 188, 188, 0.5);
}
.btn-check:checked + .btn-border-color, .btn-check:active + .btn-border-color, .btn-border-color:active, .btn-border-color.active, .show > .btn-border-color.dropdown-toggle {
  color: #000000;
  background-color: #e4e4e4;
  border-color: #e0e0e0;
}
.btn-check:checked + .btn-border-color:focus, .btn-check:active + .btn-border-color:focus, .btn-border-color:active:focus, .btn-border-color.active:focus, .show > .btn-border-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(188, 188, 188, 0.5);
}
.btn-border-color:disabled, .btn-border-color.disabled {
  color: #000000;
  background-color: #dddddd;
  border-color: #dddddd;
}

.btn-outline-blue {
  color: #4726e1;
  border-color: #4726e1;
}
.btn-outline-blue:hover {
  color: #ffffff;
  background-color: #4726e1;
  border-color: #4726e1;
}
.btn-check:focus + .btn-outline-blue, .btn-outline-blue:focus {
  box-shadow: 0 0 0 0 rgba(71, 38, 225, 0.5);
}
.btn-check:checked + .btn-outline-blue, .btn-check:active + .btn-outline-blue, .btn-outline-blue:active, .btn-outline-blue.active, .btn-outline-blue.dropdown-toggle.show {
  color: #ffffff;
  background-color: #4726e1;
  border-color: #4726e1;
}
.btn-check:checked + .btn-outline-blue:focus, .btn-check:active + .btn-outline-blue:focus, .btn-outline-blue:active:focus, .btn-outline-blue.active:focus, .btn-outline-blue.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(71, 38, 225, 0.5);
}
.btn-outline-blue:disabled, .btn-outline-blue.disabled {
  color: #4726e1;
  background-color: transparent;
}

.btn-outline-indigo {
  color: #6610f2;
  border-color: #6610f2;
}
.btn-outline-indigo:hover {
  color: #ffffff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-check:focus + .btn-outline-indigo, .btn-outline-indigo:focus {
  box-shadow: 0 0 0 0 rgba(102, 16, 242, 0.5);
}
.btn-check:checked + .btn-outline-indigo, .btn-check:active + .btn-outline-indigo, .btn-outline-indigo:active, .btn-outline-indigo.active, .btn-outline-indigo.dropdown-toggle.show {
  color: #ffffff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-check:checked + .btn-outline-indigo:focus, .btn-check:active + .btn-outline-indigo:focus, .btn-outline-indigo:active:focus, .btn-outline-indigo.active:focus, .btn-outline-indigo.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(102, 16, 242, 0.5);
}
.btn-outline-indigo:disabled, .btn-outline-indigo.disabled {
  color: #6610f2;
  background-color: transparent;
}

.btn-outline-purple {
  color: #300d81;
  border-color: #300d81;
}
.btn-outline-purple:hover {
  color: #ffffff;
  background-color: #300d81;
  border-color: #300d81;
}
.btn-check:focus + .btn-outline-purple, .btn-outline-purple:focus {
  box-shadow: 0 0 0 0 rgba(48, 13, 129, 0.5);
}
.btn-check:checked + .btn-outline-purple, .btn-check:active + .btn-outline-purple, .btn-outline-purple:active, .btn-outline-purple.active, .btn-outline-purple.dropdown-toggle.show {
  color: #ffffff;
  background-color: #300d81;
  border-color: #300d81;
}
.btn-check:checked + .btn-outline-purple:focus, .btn-check:active + .btn-outline-purple:focus, .btn-outline-purple:active:focus, .btn-outline-purple.active:focus, .btn-outline-purple.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(48, 13, 129, 0.5);
}
.btn-outline-purple:disabled, .btn-outline-purple.disabled {
  color: #300d81;
  background-color: transparent;
}

.btn-outline-pink {
  color: #d63384;
  border-color: #d63384;
}
.btn-outline-pink:hover {
  color: #ffffff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-check:focus + .btn-outline-pink, .btn-outline-pink:focus {
  box-shadow: 0 0 0 0 rgba(214, 51, 132, 0.5);
}
.btn-check:checked + .btn-outline-pink, .btn-check:active + .btn-outline-pink, .btn-outline-pink:active, .btn-outline-pink.active, .btn-outline-pink.dropdown-toggle.show {
  color: #ffffff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-check:checked + .btn-outline-pink:focus, .btn-check:active + .btn-outline-pink:focus, .btn-outline-pink:active:focus, .btn-outline-pink.active:focus, .btn-outline-pink.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(214, 51, 132, 0.5);
}
.btn-outline-pink:disabled, .btn-outline-pink.disabled {
  color: #d63384;
  background-color: transparent;
}

.btn-outline-red {
  color: #d34f2d;
  border-color: #d34f2d;
}
.btn-outline-red:hover {
  color: #000000;
  background-color: #d34f2d;
  border-color: #d34f2d;
}
.btn-check:focus + .btn-outline-red, .btn-outline-red:focus {
  box-shadow: 0 0 0 0 rgba(211, 79, 45, 0.5);
}
.btn-check:checked + .btn-outline-red, .btn-check:active + .btn-outline-red, .btn-outline-red:active, .btn-outline-red.active, .btn-outline-red.dropdown-toggle.show {
  color: #000000;
  background-color: #d34f2d;
  border-color: #d34f2d;
}
.btn-check:checked + .btn-outline-red:focus, .btn-check:active + .btn-outline-red:focus, .btn-outline-red:active:focus, .btn-outline-red.active:focus, .btn-outline-red.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(211, 79, 45, 0.5);
}
.btn-outline-red:disabled, .btn-outline-red.disabled {
  color: #d34f2d;
  background-color: transparent;
}

.btn-outline-orange {
  color: #f08d34;
  border-color: #f08d34;
}
.btn-outline-orange:hover {
  color: #000000;
  background-color: #f08d34;
  border-color: #f08d34;
}
.btn-check:focus + .btn-outline-orange, .btn-outline-orange:focus {
  box-shadow: 0 0 0 0 rgba(240, 141, 52, 0.5);
}
.btn-check:checked + .btn-outline-orange, .btn-check:active + .btn-outline-orange, .btn-outline-orange:active, .btn-outline-orange.active, .btn-outline-orange.dropdown-toggle.show {
  color: #000000;
  background-color: #f08d34;
  border-color: #f08d34;
}
.btn-check:checked + .btn-outline-orange:focus, .btn-check:active + .btn-outline-orange:focus, .btn-outline-orange:active:focus, .btn-outline-orange.active:focus, .btn-outline-orange.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(240, 141, 52, 0.5);
}
.btn-outline-orange:disabled, .btn-outline-orange.disabled {
  color: #f08d34;
  background-color: transparent;
}

.btn-outline-yellow {
  color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-outline-yellow:hover {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-check:focus + .btn-outline-yellow, .btn-outline-yellow:focus {
  box-shadow: 0 0 0 0 rgba(248, 187, 0, 0.5);
}
.btn-check:checked + .btn-outline-yellow, .btn-check:active + .btn-outline-yellow, .btn-outline-yellow:active, .btn-outline-yellow.active, .btn-outline-yellow.dropdown-toggle.show {
  color: #000000;
  background-color: rgb(248, 187, 0);
  border-color: rgb(248, 187, 0);
}
.btn-check:checked + .btn-outline-yellow:focus, .btn-check:active + .btn-outline-yellow:focus, .btn-outline-yellow:active:focus, .btn-outline-yellow.active:focus, .btn-outline-yellow.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(248, 187, 0, 0.5);
}
.btn-outline-yellow:disabled, .btn-outline-yellow.disabled {
  color: rgb(248, 187, 0);
  background-color: transparent;
}

.btn-outline-green {
  color: #1d4b40;
  border-color: #1d4b40;
}
.btn-outline-green:hover {
  color: #ffffff;
  background-color: #1d4b40;
  border-color: #1d4b40;
}
.btn-check:focus + .btn-outline-green, .btn-outline-green:focus {
  box-shadow: 0 0 0 0 rgba(29, 75, 64, 0.5);
}
.btn-check:checked + .btn-outline-green, .btn-check:active + .btn-outline-green, .btn-outline-green:active, .btn-outline-green.active, .btn-outline-green.dropdown-toggle.show {
  color: #ffffff;
  background-color: #1d4b40;
  border-color: #1d4b40;
}
.btn-check:checked + .btn-outline-green:focus, .btn-check:active + .btn-outline-green:focus, .btn-outline-green:active:focus, .btn-outline-green.active:focus, .btn-outline-green.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(29, 75, 64, 0.5);
}
.btn-outline-green:disabled, .btn-outline-green.disabled {
  color: #1d4b40;
  background-color: transparent;
}

.btn-outline-teal {
  color: #20c997;
  border-color: #20c997;
}
.btn-outline-teal:hover {
  color: #000000;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-check:focus + .btn-outline-teal, .btn-outline-teal:focus {
  box-shadow: 0 0 0 0 rgba(32, 201, 151, 0.5);
}
.btn-check:checked + .btn-outline-teal, .btn-check:active + .btn-outline-teal, .btn-outline-teal:active, .btn-outline-teal.active, .btn-outline-teal.dropdown-toggle.show {
  color: #000000;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-check:checked + .btn-outline-teal:focus, .btn-check:active + .btn-outline-teal:focus, .btn-outline-teal:active:focus, .btn-outline-teal.active:focus, .btn-outline-teal.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(32, 201, 151, 0.5);
}
.btn-outline-teal:disabled, .btn-outline-teal.disabled {
  color: #20c997;
  background-color: transparent;
}

.btn-outline-cyan {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-cyan:hover {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-cyan, .btn-outline-cyan:focus {
  box-shadow: 0 0 0 0 rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-cyan, .btn-check:active + .btn-outline-cyan, .btn-outline-cyan:active, .btn-outline-cyan.active, .btn-outline-cyan.dropdown-toggle.show {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-cyan:focus, .btn-check:active + .btn-outline-cyan:focus, .btn-outline-cyan:active:focus, .btn-outline-cyan.active:focus, .btn-outline-cyan.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(13, 202, 240, 0.5);
}
.btn-outline-cyan:disabled, .btn-outline-cyan.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-white:hover {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #ffffff;
  background-color: transparent;
}

.btn-outline-gray {
  color: #999999;
  border-color: #999999;
}
.btn-outline-gray:hover {
  color: #000000;
  background-color: #999999;
  border-color: #999999;
}
.btn-check:focus + .btn-outline-gray, .btn-outline-gray:focus {
  box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5);
}
.btn-check:checked + .btn-outline-gray, .btn-check:active + .btn-outline-gray, .btn-outline-gray:active, .btn-outline-gray.active, .btn-outline-gray.dropdown-toggle.show {
  color: #000000;
  background-color: #999999;
  border-color: #999999;
}
.btn-check:checked + .btn-outline-gray:focus, .btn-check:active + .btn-outline-gray:focus, .btn-outline-gray:active:focus, .btn-outline-gray.active:focus, .btn-outline-gray.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5);
}
.btn-outline-gray:disabled, .btn-outline-gray.disabled {
  color: #999999;
  background-color: transparent;
}

.btn-outline-gray-dark {
  color: #555555;
  border-color: #555555;
}
.btn-outline-gray-dark:hover {
  color: #ffffff;
  background-color: #555555;
  border-color: #555555;
}
.btn-check:focus + .btn-outline-gray-dark, .btn-outline-gray-dark:focus {
  box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5);
}
.btn-check:checked + .btn-outline-gray-dark, .btn-check:active + .btn-outline-gray-dark, .btn-outline-gray-dark:active, .btn-outline-gray-dark.active, .btn-outline-gray-dark.dropdown-toggle.show {
  color: #ffffff;
  background-color: #555555;
  border-color: #555555;
}
.btn-check:checked + .btn-outline-gray-dark:focus, .btn-check:active + .btn-outline-gray-dark:focus, .btn-outline-gray-dark:active:focus, .btn-outline-gray-dark.active:focus, .btn-outline-gray-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5);
}
.btn-outline-gray-dark:disabled, .btn-outline-gray-dark.disabled {
  color: #555555;
  background-color: transparent;
}

.btn-outline-black {
  color: #000000;
  border-color: #000000;
}
.btn-outline-black:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-check:focus + .btn-outline-black, .btn-outline-black:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
}
.btn-check:checked + .btn-outline-black, .btn-check:active + .btn-outline-black, .btn-outline-black:active, .btn-outline-black.active, .btn-outline-black.dropdown-toggle.show {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-check:checked + .btn-outline-black:focus, .btn-check:active + .btn-outline-black:focus, .btn-outline-black:active:focus, .btn-outline-black.active:focus, .btn-outline-black.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
}
.btn-outline-black:disabled, .btn-outline-black.disabled {
  color: #000000;
  background-color: transparent;
}

.btn-outline-gold {
  color: #807764;
  border-color: #807764;
}
.btn-outline-gold:hover {
  color: #000000;
  background-color: #807764;
  border-color: #807764;
}
.btn-check:focus + .btn-outline-gold, .btn-outline-gold:focus {
  box-shadow: 0 0 0 0 rgba(128, 119, 100, 0.5);
}
.btn-check:checked + .btn-outline-gold, .btn-check:active + .btn-outline-gold, .btn-outline-gold:active, .btn-outline-gold.active, .btn-outline-gold.dropdown-toggle.show {
  color: #000000;
  background-color: #807764;
  border-color: #807764;
}
.btn-check:checked + .btn-outline-gold:focus, .btn-check:active + .btn-outline-gold:focus, .btn-outline-gold:active:focus, .btn-outline-gold.active:focus, .btn-outline-gold.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(128, 119, 100, 0.5);
}
.btn-outline-gold:disabled, .btn-outline-gold.disabled {
  color: #807764;
  background-color: transparent;
}

.btn-outline-border-color {
  color: #dddddd;
  border-color: #dddddd;
}
.btn-outline-border-color:hover {
  color: #000000;
  background-color: #dddddd;
  border-color: #dddddd;
}
.btn-check:focus + .btn-outline-border-color, .btn-outline-border-color:focus {
  box-shadow: 0 0 0 0 rgba(221, 221, 221, 0.5);
}
.btn-check:checked + .btn-outline-border-color, .btn-check:active + .btn-outline-border-color, .btn-outline-border-color:active, .btn-outline-border-color.active, .btn-outline-border-color.dropdown-toggle.show {
  color: #000000;
  background-color: #dddddd;
  border-color: #dddddd;
}
.btn-check:checked + .btn-outline-border-color:focus, .btn-check:active + .btn-outline-border-color:focus, .btn-outline-border-color:active:focus, .btn-outline-border-color.active:focus, .btn-outline-border-color.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(221, 221, 221, 0.5);
}
.btn-outline-border-color:disabled, .btn-outline-border-color.disabled {
  color: #dddddd;
  background-color: transparent;
}

.btn-with-icon {
  position: relative;
  padding-right: 4rem;
  text-align: left;
}
.btn-with-icon .bi {
  transform: translate(0, -50%);
  position: absolute;
  top: 50%;
  right: 2rem;
}
.btn-with-icon.btn-sm, .btn-group-sm > .btn-with-icon.btn {
  padding-right: 3rem;
}
.btn-with-icon.btn-sm .bi, .btn-group-sm > .btn-with-icon.btn .bi {
  right: 1.5rem;
}
.btn-with-icon.btn-lg, .btn-group-lg > .btn-with-icon.btn {
  padding-right: 5rem;
}
.btn-with-icon.btn-lg .bi, .btn-group-lg > .btn-with-icon.btn .bi {
  right: 2.5rem;
}

.btn-icon {
  position: relative;
  box-sizing: content-box;
  width: 1.5em;
  height: 1.5em;
  padding: 1rem;
}
.btn-icon[class*=-sm] {
  padding: 0.75rem;
}
.btn-icon[class*=-lg] {
  padding: 1.25rem;
}
.btn-icon[class*=-xl] {
  padding: 1.875rem;
  font-size: 1.5rem;
}
.btn-icon .bi {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.btn img {
  width: auto;
  height: 2rem;
}

.btn-white:hover {
  background-color: #eeeeee;
}

.btn-outline-light {
  color: #000000;
}

.btn-filter {
  display: inline-block;
  border: 1px solid #dddddd;
  color: #555555;
}
.btn-filter:focus {
  outline: none;
  box-shadow: none;
}
.btn-filter:not(:last-child) {
  margin-right: 0.25rem;
}
.btn-filter:hover {
  border-color: #000000;
}
.btn-filter.current {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}

.pagination {
  display: inline-flex;
  border-radius: 2.5rem;
}
.pagination .page-item + .page-item {
  margin-left: 0.25rem;
}
.pagination .page-link {
  padding: 0 1rem;
  text-align: center;
  color: inherit;
  border-radius: 5rem;
  min-width: calc(
      1.7em + 2rem
    );
  height: calc(1.7em + 2rem);
  line-height: calc(
      1.7em + 2rem
    );
  border-color: transparent;
}
.pagination[class*=-sm] .page-link {
  min-width: calc(
        1.7em + 1.5rem
      );
  height: calc(
        1.7em + 1.5rem
      );
  line-height: calc(
        1.7em + 1.5rem
      );
}
.pagination[class*=-lg] .page-link {
  min-width: calc(
        1.7em + 2.5rem
      );
  height: calc(
        1.7em + 2.5rem
      );
  line-height: calc(
        1.7em + 2.5rem
      );
}

.table td,
.table th {
  padding: 1.5rem 1rem;
}
.table th {
  font-weight: 400;
}
.table tr > *:first-child {
  padding-left: 0;
}
.table tr > *:last-child {
  padding-right: 0;
}
.table thead th {
  text-transform: uppercase;
  font-size: 1.05rem;
  letter-spacing: 0.05em;
  color: #777777;
}
.table tbody tr {
  border-top-width: 1px;
}
.table td,
.table th,
.table tr {
  border-width: 0;
}

.table-bordered {
  border: 1px solid #dddddd;
}
.table-bordered thead {
  border-bottom-width: 1px;
}
.table-bordered tbody tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.05);
}

pre[class*=language-],
code[class*=language-] {
  padding: 0;
  background: transparent;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1.05rem;
  text-shadow: none;
  max-height: 50vh;
}

div.code-toolbar {
  position: relative;
}
div.code-toolbar:hover .toolbar {
  opacity: 1;
}
div.code-toolbar > .toolbar {
  transition: all 0.2s;
  transition-delay: 0s;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
}
div.code-toolbar > .toolbar .toolbar-item {
  display: block;
}
div.code-toolbar > .toolbar button {
  transition: all 0.2s;
  transition-delay: 0s;
  background: #ffffff;
  color: #000000;
  display: block;
  padding: 0.25rem 0.75rem;
  font-size: 1.05rem;
  font-family: "Ubuntu", "serif";
  text-transform: uppercase;
  border: 0;
}
div.code-toolbar > .toolbar button:hover {
  background: rgb(248, 187, 0);
  color: #ffffff;
}

kbd {
  margin: 0 0.25rem;
  padding: 0.25rem 0.5rem;
  background: #000000;
  color: #ffffff;
  letter-spacing: 0.1em;
}

.inverted pre[class*=language-],
.inverted code[class*=language-] {
  color: #ffcb2c;
}
.inverted .token {
  color: rgba(255, 255, 255, 0.5);
}
.inverted .token.selector, .inverted .token.punctuation {
  color: #ffffff;
}

.modal-close {
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 8;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  background: 0;
  padding: 0;
  font-size: 1.75rem;
  border: 0;
  color: inherit;
}
.modal-close:hover {
  background: rgba(0, 0, 0, 0.05);
}

.breadcrumb {
  display: inline-flex;
  background: transparent;
}

.breadcrumb-item + .breadcrumb-item {
  margin-left: 1rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  margin-right: 1rem;
}
.breadcrumb-item a {
  color: inherit;
}

.offcanvas-end {
  width: 100%;
  padding: 2.5rem;
  border: 0;
}
@media (min-width: 992px) {
  .offcanvas-end {
    max-width: 50vw;
    padding: 5rem;
  }
}
@media (min-width: 1200px) {
  .offcanvas-end {
    max-width: 35vw;
    padding: 5rem;
  }
}
.offcanvas-end .offcanvas-body {
  padding: 0;
}

.offcanvas-wrap {
  transition: transform 0.4s ease-in-out;
}

.offcanvas-push .offcanvas-wrap {
  transform: translateX(-6.25rem);
}

.offcanvas-header {
  margin: 0 0 2rem 0;
  padding: 0 0 2rem 0;
  border-bottom: 1px solid #dddddd;
}

.offcanvas-title {
  font-size: 1.35rem;
}

.product-image {
  position: relative;
  margin-bottom: 0.75rem;
}
.product-image img {
  max-width: 100%;
}
.product-image:hover img + img {
  opacity: 1;
}
.product-image a {
  position: relative;
  display: block;
}
.product-image img + img {
  transition: all 0.2s;
  transition-delay: 0s;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.product-title {
  display: flex;
  color: #000000;
}

.progress {
  border-radius: 1.5625rem;
  overflow: visible;
  height: 1.25rem;
}
.progress .progress-bar {
  border-radius: 1.5625rem;
  position: relative;
  overflow: visible;
  height: 1.25rem;
}

.scroll-down {
  transform: rotate(-45deg);
  position: absolute;
  bottom: 5rem;
  left: 50%;
  width: 1rem;
  height: 1rem;
  margin-left: -12px;
  border-left: 0.125rem solid #ffffff;
  border-bottom: 0.125rem solid #ffffff;
  -webkit-animation: scrollDown 1.4s infinite;
  animation: scrollDown 1.4s infinite;
  z-index: 8;
}

@keyframes scrollDown {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-100%, 100%);
    opacity: 0;
  }
}
.rotate {
  animation: rotate 16s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.grid {
  position: relative;
}
.grid > * {
  opacity: 0;
  position: absolute;
}
.grid > *[class*=-shown] {
  opacity: 1;
}

[data-isotope] {
  position: relative;
}

.logo {
  display: inline-block !important;
  height: auto;
  width: 7.5rem;
  max-width: 100%;
}
.logo[class*=-sm] {
  width: 5rem;
}

.countdown {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
}
.countdown .countdown-item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 50%;
}
@media (min-width: 768px) {
  .countdown .countdown-item {
    width: 25%;
  }
}
.countdown .countdown-value {
  display: flex;
  flex-direction: column;
  font-size: 1.05rem;
}
.countdown .countdown-value span {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1em;
}

.countdown-decorated .countdown-item {
  padding: 0.25rem;
}
.countdown-decorated .countdown-item::before {
  display: block;
  content: "";
  width: 1px;
  padding-top: 100%;
}
.countdown-decorated .countdown-value {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
}
.countdown-decorated .countdown-value span {
  font-size: 3rem;
}

.counter {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.counter:focus {
  outline: none;
  box-shadow: none;
}
.counter .counter-value {
  transition: all 0.3s ease-in-out;
  appearance: none;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  line-height: 1.5em;
  text-align: center;
  font-size: inherit;
  color: inherit;
  appearance: textfield;
  border: 0;
}
.counter .counter-value:focus {
  outline: none;
  box-shadow: none;
}
.counter .counter-value::-webkit-outer-spin-button, .counter .counter-value::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.counter .counter-value:focus, .counter .counter-value:hover {
  color: rgb(248, 187, 0);
}
.counter .counter-minus,
.counter .counter-plus {
  transition: all 0.1s;
  width: 1em;
  height: 1.5em;
  text-align: center;
  line-height: 1.5em;
  cursor: pointer;
}
.counter .counter-minus:hover,
.counter .counter-plus:hover {
  color: #000000;
}
.counter .counter-minus:active,
.counter .counter-plus:active {
  color: rgb(248, 187, 0);
}

.sticky-top {
  transition: top 0.2s;
}

.headroom--not-top .sticky-top {
  top: 0;
}
.headroom--not-top .toc-container {
  max-height: 100vh;
}
.headroom--not-top.headroom--pinned .sticky-top {
  top: 5.625rem;
}

.toc-container {
  padding: 2rem 0;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
}

.file-structure {
  padding: 0;
  list-style: none;
  color: #000000;
}
.file-structure li:not(:first-child) {
  margin-top: 0.25rem;
}
.file-structure ul {
  padding-left: 2rem;
  list-style: none;
}
.file-structure ul li:first-child {
  padding-top: 0.25rem;
}
.file-structure ul li:last-child {
  padding-bottom: 0.25rem;
}
.file-structure a,
.file-structure span {
  position: relative;
  color: inherit;
  display: block;
  padding-left: 2rem;
  line-height: 2rem;
}
.file-structure a::before,
.file-structure span::before {
  position: absolute;
  left: 0;
  content: "";
  font-family: bootstrap-icons !important;
  content: "\f38b";
  font-size: 1.5rem;
  color: inherit;
}
.file-structure a,
.file-structure span.folder {
  color: #000000;
}
.file-structure a:hover,
.file-structure span.folder:hover {
  color: rgb(248, 187, 0);
}
.file-structure a[aria-expanded=true]::before,
.file-structure span.folder[aria-expanded=true]::before {
  content: "\f3d2";
}
.file-structure a::before,
.file-structure span.folder::before {
  content: "\f3d3";
}
.file-structure span.folder:hover {
  color: #000000;
}
.file-structure span.folder::before {
  content: "\f3d7";
}

.noUi-target {
  margin: 0 1rem;
  border: 0;
  border-radius: 0;
  height: 2rem;
  box-shadow: none;
  background: transparent;
}

.noUi-connects {
  width: calc(100% + 32px);
  left: -1rem;
  right: -1rem;
  top: 0.9375rem;
  height: 0.125rem;
  background: #dddddd;
}
.noUi-connects .noUi-connect {
  background: #000000;
}

.noUi-horizontal .noUi-handle {
  top: 0;
  border: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  box-shadow: none;
  background: #ffffff;
  cursor: pointer;
}
.noUi-horizontal .noUi-handle:focus {
  outline: none;
  box-shadow: none;
}
.noUi-horizontal .noUi-handle::before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.75rem;
  margin-left: -0.75rem;
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  background: #000000;
  border-radius: 50%;
}
.noUi-horizontal .noUi-handle:hover::before {
  background: rgb(248, 187, 0);
}
.noUi-horizontal .noUi-handle::after {
  display: none;
}

.range-slider-selection {
  margin-top: 0.5rem;
  font-size: 1.05rem;
}

.range-slider-value::before {
  content: "$";
}

.typed-cursor {
  font-weight: 300;
  color: inherit;
  font-size: inherit;
}